import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DebugService } from '../../../services/debug.service';
import { CONSOLE_STYLE as cs } from '../../../configs/constant.flags';
import { FrameAvailability } from '../../models/frame.model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { ScrollService } from '../../services/scroll.service';
import { MatTooltip } from '@angular/material/tooltip';
import { FrameService } from '../../services/frame.service';
import { DataService,BinaryType } from '../../services/data.service';

@Component({
    selector: 'vis-frame-color-dot',
    template: `<div class="{{layoutcss}} clickable" vcldAutomationId="consr_vto_vis-frame-color-dot_btn" (click)="onColorDotClick()" #tooltip="matTooltip" [matTooltip]="colorName" [matTooltipDisabled]="!colorName" [ngClass]="{'unavailable': !(isFrameAvailable$ | async)}" ><span *ngIf="imgSrc" class="color-dot" [ngClass]="{'selected': selected}">
    <img class="{{imagecss}}" draggable="false" [src]="imgSrc" [ngStyle]="{'opacity':(isFrameAvailable$ | async) ? 'unset' : '0.4'}"/>
</span></div>`,
    styleUrls: ['./frame-color-dot.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class FrameColorDotComponent implements OnInit {
    @Input() public frameId: string;

    @Input() public colorName: string;

    @Input() public layoutcss: string;

    @Input() public imagecss: string;

    @Input() public selected: boolean = false;

    @Output()
    public onChange: EventEmitter<string> = new EventEmitter();

    @ViewChild('tooltip', { static: true }) tooltip: MatTooltip;

    constructor(
        private readonly sanitizer: DomSanitizer,
        private debug: DebugService,
        private changeDetector: ChangeDetectorRef,
        private _scroll: ScrollService,
        private _frames:FrameService,
        private _datacache:DataService,
    ) { }

    private _availabilityStatus$: Observable<FrameAvailability | string>;

    private _frameID$: BehaviorSubject<string>;

    public isFrameAvailable$: Observable<boolean>;

    public imgSrc: SafeUrl;

    private readonly defaultThumbnail = '../../../../assets/images/temp_color_thumbnail.png';

    public async ngOnInit() {

        this._datacache.getBinary( BinaryType.COLOR_THUMBNAIL,this.frameId, true)
            .then(thumbnail => { thumbnail ? this.imgSrc = this.sanitizer.bypassSecurityTrustUrl(thumbnail) : this.imgSrc = this.defaultThumbnail; this.changeDetector.markForCheck(); })
            .catch(err => this.debug.log(err));

        this._frameID$ = new BehaviorSubject(this.frameId);

        this._availabilityStatus$ = combineLatest([this._frameID$, this._frames.allFrames$]).pipe(
            map(([id, frames]) => frames.filter(f => f.id === id).pop().availabilityStatus)
        );



        this.isFrameAvailable$ = this._availabilityStatus$.pipe(
            map(status => parseInt(FrameAvailability[status]) == FrameAvailability.AVAILABLE)
        );

        const sub = this._scroll.scrollDispatcher$.pipe(
            tap(() => {
                this.tooltip?.hide();
            }),
            finalize(() => {
                sub.unsubscribe();
            })
        ).subscribe();

    }

    public async ngOnChanges(changes: SimpleChanges) {
        if (changes.frameId) {
            this._datacache.getBinary( BinaryType.COLOR_THUMBNAIL,changes.frameId.currentValue,true)
                .then(thumbnail => { thumbnail ? this.imgSrc = this.sanitizer.bypassSecurityTrustUrl(thumbnail) : this.imgSrc = this.defaultThumbnail; })
                .catch(err => this.debug.log(err));
        }
    }

    public onColorDotClick() {
        this.debug.log(`%c[ColorDot] %cclick on the color dot with id %c${this.frameId}`, cs.prefix, cs.clear, cs.success);
        this.onChange.emit(this.frameId);
    }
}
