<div class="row static-wrapper">
    <div class="col s12 xl6 l10 offset-xl3 offset-l1 center landscape-fix">

        <div class="col s12 headline only-mobile space-leftright" style="margin-top: calc(32px + 12px);">
            <p style="font-size: var(--headline-font-size); font-weight: bold;">
                {{ "pages.unsubscribe.title" | translate }}
            </p>
        </div>

        <div class="col xl10 l10 m8 s12 offset-xl1 offset-l1 offset-m2 headline not-mobile">
            <p style="font-size: var(--headline-font-size); font-weight: bold">
                {{ "pages.unsubscribe.title" | translate }}
            </p>
        </div>


        <div class="col s12 seperation-line">
            <hr style="width: 80%; margin-left: 10%" />
        </div>

        <div id="controls" class="col s12">

            <div *ngIf="!unsubscribed" class="col s10 offset-s1 m6 l6 xl6 offset-m3 offset-l3 offset-xl3">
                <button mat-flat-button class="col s12 spacer" color="primary" (click)="unsubscribe()" >
                    {{ "pages.unsubscribe.btn_unsubscribe" | translate }}
                </button>
                <button mat-button class="col s12" color="primary" routerLink="/landing">
                    {{ "pages.unsubscribe.btn_no" | translate }}
                </button>
            </div>

            <div *ngIf="unsubscribed" class="col xl8 l8 m8 s12 offset-xl2 offset-l2 offset-m2">
                <div class="col s12 cyan-text space-leftright spacer">
                    <div class="col s2">
                        <mat-icon class="check">done</mat-icon>
                    </div>
                    <div class="col s10 vertical-align">
                        <div class="col s12 center font">
                            <p> {{ "pages.unsubscribe.success" | translate }} </p>
                            <p> {{ "pages.unsubscribe.close_tab" | translate }} </p>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>