import { Observable } from 'rxjs';
import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';

export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>;
    resetDeactivate: () => void;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
    canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
        // if there are changes send confirmation dialog | otherwise allow deactivate
        const result = component.canDeactivate() ? true : confirm('You have unsaved changes!');
        if(result) {
            component.resetDeactivate();
        }

        return result;
    }
}
