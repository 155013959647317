<div class="dialog-header">
    <p mat-dialog-title class="headline-wrapper bold">{{ "pages.FAQ.title" | translate }}</p>
    <button vcldAutomationId="consr_vto_vis-faq-page_close-FAQ_btn" mat-icon-button class="close-btn" [mat-dialog-close]="true">
        <mat-icon>close</mat-icon>
    </button>
</div>


<mat-dialog-content>
    <p class="text">{{ "pages.FAQ.subline" | translate}}<a vcldAutomationId="consr_vto_vis-faq-page_send-us-msg_link" class="selected pointer" color="primary"
            (click)="openSupport()">{{"pages.FAQ.link" | translate}}</a></p>

    <div class="accordion-container">
        <p class="subline">{{ "pages.FAQ.FAQs" | translate }}</p>
        <mat-accordion multi [@.disabled]="disableAnimation">
            <mat-expansion-panel vcldAutomationId="consr_vis-faq-page_expand-help-topic_btn" *ngFor="let item of 'pages.FAQ.questions' | translate | keyvalue; let i = index"
                (opened)="openPanel('faq-' + i)" (closed)="closePanel('faq-' + i)" [id]="'faq-' + i">
                <mat-expansion-panel-header>
                    <mat-panel-title class="text" [ngClass]="getCSS('faq-' + i)">{{ item.value.question }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p class="answer">{{ item.value.answer }}</p>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <div class="version-info">
        <p>{{ "pages.FAQ.version" | translate }} {{ versionNumber }}<mat-icon vcldAutomationId="consr_vto_vis-faq-page_version-info_tip" (click)="openAbout()"
                class="info-icon">info</mat-icon></p>
                <p (click)="onLicense()">{{'components.buttons.license' | translate}}
                    <mat-icon vcldAutomationId="consr_vto_vis-faq-page_licence-info_tip" class="info-icon" matTooltip="{{'components.buttons.license' | translate}}">privacy_tip</mat-icon>
                </p>
    </div>
</mat-dialog-content>