import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import * as uuid from 'uuid';


@Injectable({
  providedIn: 'root'
})

export class ScriptLoaderService {
  private loadedLibraries: { [scriptId: string]: ReplaySubject<any> } = {};

  private readonly TAG_MANAGER_ELEMENT_ID = 'teconsent';

  constructor(@Inject(DOCUMENT) private readonly document: Document, private translate: TranslateService) { }

  loadScript$(url: string, forceLoading = false, removeScriptTagFromDOMafterLoaded = false): Observable<string> {
    const scriptId: string = uuid.v4();
    this.loadedLibraries[scriptId] = new ReplaySubject();
    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.async = false;
    script.src = url;
    script.crossOrigin = undefined;
    script.onload = () => {
      this.loadedLibraries[scriptId].next(script);
      this.loadedLibraries[scriptId].complete();

      if (removeScriptTagFromDOMafterLoaded) {
        this.document.body.removeChild(script);
      }

      this.removeText();
    };
    script.onerror = (e) => {
      this.loadedLibraries[scriptId].error(e);
      if (removeScriptTagFromDOMafterLoaded) {
        this.document.body.removeChild(script);
      }
    };

    this.document.body.appendChild(script);

    return of(scriptId);
  }

  removeScript$(id: string): Observable<void> {
    const script$ = this.loadedLibraries[id];
    if (script$) {
      return script$.pipe(
        map(script => {
          this.document.body.removeChild(script);
          return void (0);
        })
      );
    }
    return of(void (0));
  }

  private removeText() {
    const id = `#${this.TAG_MANAGER_ELEMENT_ID} a`;
    const element = this.document.querySelector(id);
    if (element) {
      element.innerHTML = this.translate.instant('components.footer.cookies');
    }
  }
}
