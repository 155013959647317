// Angular Core Modules
import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";

// Material Imports
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

// Translation and Localization
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

// VisuCloud Environment variables
import { VISEnvironmentModule } from "visenvironment";

// Visucloud Vendors Module (Angular/Material/Components)
import { VISVendorsModule } from "visvendors";

// VisuCloud Utils (NotFoundPage, SecureImage)
import { UtilitiesModule } from "utilities";
import { ConsumerErrorHandler } from "./handler/consumerErrorHandler.service";

// VisuCloud Security Module (Authorization)
import { VISAutoAuthModule } from "visauto-auth";

// Consumer Portal Components
import { AppComponent } from "./components/appComponent/app.component";

import { VtoModule } from "./vto/vto.module";
import { LandingPageComponent } from "./pages/landing/landing.page.component";

import { LanguageSelectionDialogComponent } from "./dialogs/language-selection/language-selection.dialog.component";
import { FAQPageComponent } from "./pages/FAQ/faq.page.component";
import { AboutDialogComponent } from "./dialogs/about/about.dialog.component";
import { PrivacyDialogComponent } from "./dialogs/privacy/privacy.dialog.component";
import { UnsubscribePageComponent } from "./pages/unsubscribe/unsubscribe.page.component";
import { RecommendationLandingPageComponent } from "./pages/recommendation-landing/recommendation-landing.page.component";
import { LicensePageComponent } from "./pages/license/license.page.component";
import { SnackbarComponent } from "./components/snackbar/snackbar.component";

import { AutoLogoutInterceptor } from './handler/auto-logout.interceptor';
import { CacheHttpInterceptor } from './handler/cache.interceptor';
import { LocationHttpInterceptor } from './handler/location.interceptor';

import { AppConfigService } from './services/appConfig.service';
import { ConsumerAppEnvironment as env } from "visenvironment";
import { FormatPricePipe } from "./vto/pipes/format-price.pipe";

export function initialitzeRuntimeAppConfig(appConfig: AppConfigService) {
    return () => appConfig.initializeAppConfig();
  }

export function initialitzeAppLinksConfig(appConfig: AppConfigService) {
    return () => appConfig.getAppLinksConfig();
}

@NgModule({
    declarations: [
        AppComponent,
        // Dialogs
        LanguageSelectionDialogComponent,
        AboutDialogComponent,
        PrivacyDialogComponent,
        // Pages
        LandingPageComponent,
        FAQPageComponent,
        UnsubscribePageComponent,
        RecommendationLandingPageComponent,
        LicensePageComponent,
        //Components
        SnackbarComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        VISEnvironmentModule,
        UtilitiesModule,
        VISVendorsModule,
        VISAutoAuthModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        VtoModule,
    ],
    providers: [
        {provide: APP_INITIALIZER, useFactory: initialitzeRuntimeAppConfig, deps: [AppConfigService], multi: true},
        {provide: APP_INITIALIZER, useFactory: initialitzeAppLinksConfig, deps: [AppConfigService], multi: true},
        { provide: ErrorHandler, useClass: ConsumerErrorHandler },
        { provide: HTTP_INTERCEPTORS, useClass: AutoLogoutInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: CacheHttpInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LocationHttpInterceptor, multi: true },
        FormatPricePipe
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, env.connectivity.translations);
}