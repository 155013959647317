import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { GoogleTagManagerService, GTMCustomEvents } from '../../services/gtm.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'vis-delete-dialog',
    templateUrl: './delete.dialog.component.html',
    styleUrls: ['./delete.dialog.component.scss']
})
export class DeleteDialogComponent {

    constructor(public dialogRef: MatDialogRef<DeleteDialogComponent>, private gtm: GoogleTagManagerService) { }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    onYesClick(): void {
        this.gtm.pushEvent(GTMCustomEvents.profile_delete_avatar_confirm);
        this.dialogRef.close(true);
    }
}
