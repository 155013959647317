<div class="dialog-header">
    <p mat-dialog-title class="col s11 headliner">{{"components.dialogs.confirm.share_selection" | translate}}</p>
    <button mat-icon-button (click)="onCancelClick()" class="close-btn">
        <mat-icon>close</mat-icon>
    </button>
    </div>



    <mat-dialog-content class="col s12">
        <div class="col s12">
            <p class="text small-space">
                {{ "components.dialogs.confirm.contact_info" | translate }}
            </p>

            <p class="text">
                {{ "components.dialogs.confirm.note" | translate }}
            </p>
        </div>

        <div class="col s12">
            <mat-form-field appearance="fill">
                <mat-label *ngIf="commentInput.value.length !== 0" class="selection">{{
                    "components.inputs.message.label" | translate
                    }}</mat-label>
                <textarea matInput placeholder="{{ 'components.dialogs.confirm.purchase_note' | translate }}" autocomplete="off" #commentInput cdkTextareaAutosize></textarea>
                <mat-error>{{
                    "components.validation.errors.required" | translate
                    }}</mat-error>
            </mat-form-field>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions class="col s12 row">
        <button mat-flat-button color="primary" (click)="onYesClick()" class="col s12 m5 l3 xl3">
            {{ "components.dialogs.confirm.share_with_optician" | translate }}
        </button>
    </mat-dialog-actions>