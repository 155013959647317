import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

import { ProfilePageComponent } from '../pages/profile/profile.page.component';
import { map } from 'rxjs/operators';
import { SessionService } from '../services/session.service';

@Injectable({
    providedIn: 'root'
})
export class NoAvatarGuard implements CanDeactivate<ProfilePageComponent> {

    constructor(private _session: SessionService) { }

    canDeactivate(): Observable<boolean> {
        return this._session.sessionsList$.pipe(
            map(s => s.length > 0)
        );
    }
}
