<ng-container *ngIf="ecpLogo$ | async; then CoBranding; else Empty"></ng-container>

<ng-template #CoBranding>
    <div class="col co-branding" [ngClass]="layout" [style]="customStyle"
        [style.visibility]="processFlag ? 'hidden' : 'visible'">
        <p class="col" style="text-align: right; font-weight: bold; line-height: 32px; padding-right: 12px;">
            {{'application.co_branding_caption' | translate}}
        </p>
        <img id="hsLogo" class="col" style="max-height: 32px; max-width: 128px;" [style.marginTop.px]="hsMargin"
            [src]="ecpLogo$ | async" />
    </div>
</ng-template>

<ng-template #Empty>
    <!-- <p class="col s12">Looks empty here!</p> -->
</ng-template>