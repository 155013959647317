import { Directive, OnInit, Input, ElementRef, HostBinding } from '@angular/core';
import { VisucloudRoleService } from '../services/auto-auth.service';


@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[secured]',
})
export class SecurtiyDirective implements OnInit {

    @Input() permission: string;

    @HostBinding('attr.disabled') public disabled;

    constructor(public el: ElementRef, private security: VisucloudRoleService) {
    }

    ngOnInit() {
        // console.log(`[Security] [Directive] check permission ${this.permission} for element ${this.el}`);

        if (this.security.hasPermission(this.permission)) {
            this.allowAccessStyle();
        } else {
            this.forbiddenAccessStyle();
        }
    }

    private forbiddenAccessStyle() {
        this.el.nativeElement.style.background = 'rgba(255,15,15,.1)';

        if (this.isButton()) {
            this.disabled = true;
        } else {
            this.el.nativeElement.style.display = 'none';
        }
    }

    private allowAccessStyle() {
        this.el.nativeElement.style.background = 'rgba(0,255,65,.1)';
    }

    private isButton() {
        return this.el.nativeElement.tagName.toUpperCase() === 'BUTTON';
    }
}

