<div class="header-image">
    <div class="info-box row">
        <div class="col s12">
            <div class="text-box col l3 s5 offset-s1 offset-l1">
                <p>{{'pages.landing.description' | translate}}</p>
            </div>
        </div>

        <button *ngIf="!isAuthenticated" mat-button (click)="onLogin()" mat-flat-button [color]="'primary'"
            class="col s6 m2 l2 xl2 offset-s1 offset-m1 offset-l1 offset-xl1">
            {{'components.buttons.login' | translate}}
        </button>
    </div>
</div>