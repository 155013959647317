import { NgModule } from '@angular/core';

// Import Authentication Moduel for OAuth2
import { OAuthModule } from 'angular-oauth2-oidc';

import { SecurtiyDirective } from './directives/permission.directive';
import { AuthGuard, RoleGuard } from './guards/auto-auth.guard';


@NgModule({
  declarations: [SecurtiyDirective],
  imports: [
    OAuthModule.forRoot()
  ],
  providers: [RoleGuard, AuthGuard],
  exports: [OAuthModule, SecurtiyDirective]
})
export class VISAutoAuthModule { }
