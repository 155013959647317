import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TintsCoatingsService } from '../services/tints-coatings.service';

@Pipe({
  name: 'tintCoatingName',
  pure: true
})
export class TintCoatingNamePipe implements PipeTransform {
  constructor(private translate: TranslateService, private _tintsCoatings: TintsCoatingsService) { }

  transform(item: any, key: string): string {
    return this._tintsCoatings.getNameByLang(key, item, this.translate.currentLang)
  }

}
