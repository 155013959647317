import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { VisucloudRoleService } from '../services/auto-auth.service';


@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private oAuth: OAuthService, private router: Router, private role: RoleGuard) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        // // console.log("next.data.permission from auth guard: " + next.data.permission);
        if (this.oAuth.hasValidIdToken()) {
            // if the route needs an authenticated user only
            if (next.data.permission !== undefined && next.data.permission !== '') {
                return this.role.canActivate(next, state);
            }

            return true;
        }

        // console.log('Sorry but I can\'t let you into the application :( ');
        // this.router.navigate(['404-not-found']);
        this.router.navigate(['']);
        return false;
    }
}

@Injectable({ providedIn: 'root' })
export class RoleGuard implements CanActivate {

    constructor(private roles: VisucloudRoleService, private router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (next.data.permission) {
            return (this.roles.getActiveRole().permissions.findIndex(p => p.key === next.data.permission) > -1);
            // return this.roles.checkAvailableRolePermission(this.roles.getActiveRole(), next.data.permission);
        }

        // console.log('[RouteGuard] [Error] couldn\'t find permission: ' + next.data.permission);
        this.router.navigate(['404-not-found']);
        return false;
    }
}

