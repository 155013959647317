import { Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { DebugService } from '../../services/debug.service';
@Directive({
    selector: '[truncated]',
    host: {
        '(mouseenter)': 'update($event)'
    }
})
export class TruncateDirective {
    
    @Input()
    public isDisabled: boolean;

    @Output()
    public isDisabledChange: EventEmitter<boolean> = new EventEmitter();
                    
            
    constructor(private el: ElementRef, private debug: DebugService) { }

    private get needTruncate(): boolean {
        const { scrollWidth, clientWidth } = this.el.nativeElement;
        
        const result = scrollWidth > clientWidth;

        return result;
    }

    update(e) {
        this.isDisabledChange.emit(!this.needTruncate);
    }
}