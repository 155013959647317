import { VisucouldPermission } from './vis-permission';
import { VisucloudRoles } from './vis-role.enum';
import { VisucloudRoleScope } from './vis-role-scope.enum';

export class VisucloudRole {

    constructor(name: VisucloudRoles, permissions: Array<VisucouldPermission>, scope: VisucloudRoleScope) {
        this.roleName = name;
        this.permissions = permissions;
        this.roleScope = scope;
    }

    private permissions: Array<VisucouldPermission>;

    private roleName: VisucloudRoles;

    private roleScope: VisucloudRoleScope;

    public get Permissions() {
        return this.permissions;
    }

    public get Name() {
        return this.roleName;
    }

    public get Scope() {
        return this.roleScope;
    }

    public hasPermission(permissionKey: string): boolean {
        return (this.permissions.find((item) => {
            return (item.Permission === permissionKey);
        }) ? true : false);
    }
}
