<mat-drawer-container
    style="position: absolute; top: 0px; left: 0px; right: 0px; bottom: 0px; padding: var(--padding-medium); padding-bottom: 0px; background-color: white;">
    <mat-drawer #filterMenu class="filter-menu" mode="over" autoFocus="false" position="end"
        (closedStart)="frameFilter.removeTemporaryFilter(sessionId)">
        <vis-filter class="gallery-filter" [sessionId]="sessionId"
            (isFilterbarClosed)="filterMenu.close(); frameFilter.removeTemporaryFilter(sessionId);"></vis-filter>
        <div class="white sticky">
            <div class="filter-btns">
                <button mat-button color="primary" vcldAutomationId="consr_vto_vis-gallery-page_clear_btn" (click)="clearFilters()"> {{ "pages.gallery.filters.clear" |
                    translate }}</button>
                <button mat-flat-button color="primary" vcldAutomationId="consr_vto_vis-gallery-page_show_btn" (click)="applyFilters()">{{"pages.gallery.filters.showfilteredframes"
                    | translate}}({{ (filteredFramesCount$ | async) || 0 }})</button>
            </div>
        </div>
    </mat-drawer>
    <mat-drawer-content class="page-container">

        <!-- Loading Spinner -->
        <div class="loader" *ngIf="isLoading$ | async">
            <mat-spinner></mat-spinner>
        </div>

        <div class="gallery-header">

            <div class="top-left">
                <!-- Back-Button -->
                <button mat-fab  vcldAutomationId="consr_vto_vis-gallery-page_back_btn" class="circle blured back-btn" (click)="closeGallery()">
                    <mat-icon>arrow_back_ios_new</mat-icon>
                </button>

                <!-- Co-Branding for Mobile -->
                <div class="cobranding" *ngIf="(screenWidth <= 640) && showLogo">
                    <vcld-cobranding layout="gallery-branding"
                        customStyle="display: flex; align-items: center; justify-content: flex-end;"
                        [session]="_session.selectedSession$ | async">
                    </vcld-cobranding>
                </div>

                <!-- Headline: Your Favorites |  Your Opticians Inventory | Favorites & Recommendations -->
                <div>
                    <ng-container *ngIf="(favoritesSelected$ | async); else opticianFrames">
                        <span class="main-headline"
                            *ngIf="(_frames.dmtRecommendedFramesCount$ | async) === 0">{{'pages.gallery.your_favourites' |
                            translate:{'favouritecount': (favoriteCount$ | async) } }}</span>
                        <span *ngIf="(_frames.dmtRecommendedFramesCount$ | async) > 0"
                            class="main-headline">{{'pages.gallery.favorites_recommendations' |
                            translate:{'favouritecount':
                            (favoriteCount$ | async) } }}</span>
                    </ng-container>

                    <ng-template #opticianFrames>
                        <span class="main-headline"> {{'pages.gallery.frames_of_optician_headline_mobile' |
                            translate}}</span>
                    </ng-template>
                </div>
            </div>

            <div class="bottom-right">
                <div class="button-wrapper">
                    <!-- list/grid toggle -->
                    <div class="only-mobile" *ngIf="!(searchFocus$ | async)">
                        <button class="list-view" mat-icon-button (click)="listActive = !listActive;frameCompareList = [];">
                            <mat-icon>{{listActive ? 'grid_view' :  'view_list' }} </mat-icon>
                        </button>
                    </div>
                    <!-- Search -->
                    <vis-frame-gallery-search *ngIf="!(favoritesSelected$ | async)" vcldAutomationId="consr_vto_vis-gallery-page_frame_search" (searchTerm)="onSearch($event)" 
                        (onFocus)="onFocusSearchBar($event)" [ngClass]="(searchFocus$ | async) ? 'searchbar' : ''">
                    </vis-frame-gallery-search>
    
                    <!-- Filter-Button -->
                    <div *ngIf="!(searchFocus$ | async)">
                        <button mat-button vcldAutomationId="consr_vto_vis-gallery-page_filter_btn" (click)="filterMenu.toggle()" *ngIf="!(favoritesSelected$ | async)" class="filter-btn">
                            <mat-icon> <i class="icon visucloud-icons filter"></i></mat-icon>
                            <span class="hidden-mobile" >{{ 'pages.gallery.filter' | translate : { 'count':
                                frameFilter.filterCount } }}</span>
                            <span class="only-mobile" >({{ frameFilter.filterCount  }})</span>
                        </button>
                    </div>
                </div>

                <!-- Toggle-Button: Your Favorites | Your Opticians Inventory -->
                <div *ngIf="!(searchFocus$ | async)">
                    <button vcldAutomationId="consr_vto_vis-gallery-page_toggle_btn"  mat-stroked-button color="primary" class="toggle-btn" (click)="toggleFavorites();">
                        <mat-icon *ngIf="!(favoritesSelected$ | async)">favorite
                        </mat-icon>
                        <ng-container *ngIf="favoritesSelected$ | async; else allFramesText"> {{
                            "pages.gallery.frames_of_optician" | translate }}
                        </ng-container>
                        <ng-template #allFramesText>{{"components.buttons.your_favourites" | translate : {
                            favouritescount: favoriteCount$ | async } }} 
                        </ng-template>
                    </button>
                </div>
            </div>
        </div>

        <!-- Frames -->
        <div #frameWrapper class="frame-wrapper" (scroll)="scrollHandler($event)">

            <!-- Our Recommedations -->
            <div *ngIf="((_frames.dmtRecommendedFramesCount$ | async) > 0) && (mode==='favorite')" style="width: 100%; height: fit-content;">
                <p class="sub-headline">{{'pages.gallery.our_recommendations' | translate }}</p>
                <virtual-scroller #recScroller [items]="(dmtFrames$ | async)" class="recommendation-wrapper" [scrollAnimationTime]="0" [enableUnequalChildrenSizes]="true" [scrollThrottlingTime]="250" (vsUpdate)="cd.detectChanges()" [parentScroll]="frameWrapper">
                    <div [class.frame-tile]="listActive" [class.frame-tile-grid]="!listActive" *ngFor="let item of recScroller.viewPortItems; trackBy: recVirtualScrollerTrackBy">
                        <vis-gallery-frame-host [frame]="item" [frameCompareList]="frameCompareList"
                            [favoriteSelectedObservable]="favoritesSelected$"
                            [pricingFlag]="(_viewer.ecpSettings$ | async)?.pricingEnabled"
                            [modelNameFlag]="(_viewer.ecpSettings$ | async)?.modelNameEnabled"
                            [colorFlag]="(_viewer.ecpSettings$ | async)?.colorEnabled"
                            [materialFlag]="(_viewer.ecpSettings$ | async)?.materialEnabled" 
                            [dmtFlag]="true" (onChange)="changeTrigger()" (onCompareCheckboxChange) = "compareFrameChanged($event)"
                            [changeTrigger$]="changeTrigger$" [favoritedFrameIdsObservable]="favoritedFrames$"
                            [sessionId]="sessionId" [mode]="mode" [isList]="listActive" [showCompare]="(frames$ | async)?.length + (dmtFrames$ | async)?.length > 1"
                            [scrollToPosition] = "recScroller.viewPortInfo.scrollStartPosition" [endIndex]="recScroller.viewPortInfo.endIndex">
                        </vis-gallery-frame-host>
                    </div>
                </virtual-scroller>
            </div>

            <!-- Your Favorites | Inventory Frames -->
            <div style="width: 100%; height: fit-content;">
                <p *ngIf="((_frames.dmtRecommendedFramesCount$ | async) > 0) && (mode==='favorite')" class="sub-headline">
                    {{'pages.gallery.your_favourites' | translate:{'favouritecount': (favoriteCount$ | async) } }}</p>

                <virtual-scroller #inventoryScroller [items]="(frames$ | async)" class="favinventory-wrapper" [scrollAnimationTime]="0" [enableUnequalChildrenSizes]="true" [scrollThrottlingTime]="250" (vsUpdate)="cd.detectChanges()" (vsEnd) = "loadMore($event)" [parentScroll]="frameWrapper" [bufferAmount]="128">
                    <div [class.frame-tile]="listActive" vcldAutomationId="consr_vto_vis-gallery-page_frame-tile_tiles" [class.frame-tile-grid]="!listActive" *ngFor="let item of inventoryScroller.viewPortItems; trackBy: virtualScrollerTrackBy">
                        <vis-gallery-frame-host [frame]="item"
                        [frameCompareList]="frameCompareList" [favoriteSelectedObservable]="favoritesSelected$"
                        [pricingFlag]="(_viewer.ecpSettings$ | async)?.pricingEnabled"
                        [modelNameFlag]="(_viewer.ecpSettings$ | async)?.modelNameEnabled"
                        [colorFlag]="(_viewer.ecpSettings$ | async)?.colorEnabled" [materialFlag]="(_viewer.ecpSettings$ | async)?.materialEnabled"
                        (onChange)="changeTrigger()" [changeTrigger$]="changeTrigger$" (onCompareCheckboxChange) = "compareFrameChanged($event)"
                        [favoritedFrameIdsObservable]="favoritedFrames$" [sessionId]="sessionId" [mode]="mode" [tint]="item?.tint"
                        [coating]="item?.coating" [isList]="listActive" [showCompare]="(frames$ | async)?.length + (dmtFrames$ | async)?.length > 1"
                        [scrollToPosition] = "inventoryScroller.viewPortInfo.scrollStartPosition" [endIndex]="inventoryScroller.viewPortInfo.endIndexWithBuffer">
                    </vis-gallery-frame-host>
                    </div>
                </virtual-scroller>           
            </div>
        </div>

        <div class="bottom-bar">

            <!-- Compare Buttons -->
            <div class="compare-btn" *ngIf="frameCompareList.length >= 2"> 
                <button mat-flat-button [color]="'primary'" vcldAutomationId="consr_vto_vis-gallery-page_compare-selected-frames_btn" vcldTooltip (click)="openCompareDialog()"
                    [disabled]="frameCompareList.length < 2"> {{ "components.buttons.comparision" | translate }}
                </button>
                <button mat-button color="primary" vcldAutomationId="consr_vto_vis-gallery-page_clear_btn" (click)="frameCompareList = []">{{ "pages.gallery.filters.clear" | translate
                    }}</button>
            </div>

            <!-- *RRP & Upward-Button -->
            <div class="rrp-upward">
                <div *ngIf="(_viewer.ecpSettings$ | async)?.pricingEnabled" class="rrp">
                    <p vcldAutomationId="consr_vto_vis-gallery-page_recommended-retail-price-of-frame_txt" class="quick-silver-text">* {{ "application.rrp" | translate }}</p>
                </div>
                <div *ngIf="frameWrapper.scrollTop > 300">
                    <button vcldAutomationId="consr_vto_vis-gallery-page_back-to-top_btn" class="circle blured upward-btn quick-silver" mat-fab (click)="backToTopClick()">
                        <mat-icon>arrow_upward</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </mat-drawer-content>
</mat-drawer-container>