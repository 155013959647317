<div class="frame-fix">
    <!-- <ng-container *ngIf="consentManager$ | async">
    </ng-container> -->

    <!-- Toolbar-header for each page-->
    <mat-toolbar class="bg02">
        <mat-toolbar-row [ngClass]="isDebugModeEnabled ? 'debug' : null">
            <ng-container
                *ngIf="(!(sessionAvailable$ | async) || !(ecpLogo$ | async) || (mobile$ | async)); then mobile; else CoBranding">
            </ng-container>

            <ng-template #mobile>
                <img vcldAutomationId="consr_vto_vis-app-root_zeiss-Logo_img" [src]="zeissLogo" (click)="onChangeRoute('profile')" class="pointer logo" alt="Carl Zeiss GmbH" />

                <span class="toolbar-title bold pointer" style="flex-grow: 1;" (click)="onChangeRoute('profile')">
                    {{'application.title' | translate}}
                </span>
            </ng-template>

            <ng-template #CoBranding>
                <img vcldAutomationId="consr_vto_vis-app-root_zeiss-Logo_img" [src]="zeissLogo" (click)="onChangeRoute('profile')" class="pointer logo" alt="Carl Zeiss GmbH" />

                <span class="toolbar-title" style="flex-grow: 1;">
                    <span class="bold pointer" style="width: fit-content;" (click)="onChangeRoute('profile')">{{'application.title' | translate}}</span>
                </span>

                <span style="flex-grow: 1; text-align: right; padding-right: 12px;"
                    class="bold presented-by">{{'application.co_branding_caption' | translate }}</span>
                <img [src]="ecpLogo$ | async" style="transform: scale(0.9);" class="opticianBrand" alt="presented by" />
            </ng-template>

            <div>
                <button vcldAutomationId="consr_vto_vis-app-root_Language-change_btn" *ngIf="!isAuthenticated" (click)="changeLanguage()" mat-icon-button>
                    <mat-icon>language</mat-icon>
                </button>

                <button vcldAutomationId="consr_vto_vis-app-root_hamburger_btn"  *ngIf="isAuthenticated" id="startMenuTrigger" mat-icon-button [matMenuTriggerFor]="startMenu">
                    <i class="visucloud-icons icon hamburger"></i>
                </button>

                <mat-menu #startMenu>
                    <button vcldAutomationId="consr_vto_vis-app-root_menu-home_btn" *ngIf="isAuthenticated" mat-menu-item #btnMenuProfile (click)="onChangeRoute('profile')">
                        <mat-icon><i class="icon visucloud-icons avatar black"></i></mat-icon>
                        <span>{{ "application.home" | translate }}</span>
                    </button>

                    <button vcldAutomationId="consr_vto_vis-app-root_menu-help_btn" *ngIf="isAuthenticated && (sessionAvailable$ | async) as se" mat-menu-item #btnMenuContact (click)="onContactSupport('ecp', se)">
                        <mat-icon>contact_support</mat-icon>
                        <span>{{ "application.help" | translate }}</span>
                    </button>

                    <button vcldAutomationId="consr_vto_vis-app-root_menu-help_btn" *ngIf="isAuthenticated && (sessionAvailable$ | async) == null" mat-menu-item #btnMenuContactZeiss (click)="onContactSupport('zeiss')">
                        <mat-icon>contact_support</mat-icon>
                        <span>{{ "application.help" | translate }}</span>
                    </button>

                    <button vcldAutomationId="consr_vto_vis-app-root_menu-intro_btn" *ngIf="isAuthenticated" #btnMenuFeedback mat-menu-item (click)="openIntro()">
                        <mat-icon #iconFeedback>emoji_objects</mat-icon>
                        <span>{{ "application.introduction" | translate }}</span>
                    </button>

                    <button vcldAutomationId="consr_vto_vis-app-root_menu-language_btn" (click)="changeLanguage()" mat-menu-item #btnMenuLanguage>
                        <mat-icon>language</mat-icon>
                        <span>{{ "application.language" | translate }}</span>
                    </button>

                    <button vcldAutomationId="consr_vto_vis-app-root_menu-logout_btn" *ngIf="isAuthenticated" #btnMenuLogout mat-menu-item
                        (click)="onLogout()">
                        <mat-icon #iconLogout class="support-brightorange-text">power_settings_new</mat-icon>
                        <span class="support-brightorange-text">{{ "components.buttons.logout" | translate }}</span>
                    </button>
                </mat-menu>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>


    <div class="content-wrapper" (scroll)="removeElement()"  [ngClass]="{'debug-outline': isDebugModeEnabled}">
        <router-outlet></router-outlet>
    </div>

    <div class="white visfooter" [ngClass]="isDebugModeEnabled ? 'debug' : null"
        [style.borderTop]="isInViewerOrCompare() ? '1px solid #A2A2A2' : 'none'">
        <div>
            <a vcldAutomationId="consr_app-root_publisher_link" [href]="impresum" target="_blank">
                {{ "components.footer.impressum" | translate }}
            </a>
        </div>
        <div>
            <a vcldAutomationId="consr_app-root_data-privacy_link" class="link" (click)="openDataPrivacyDialog()">
                {{"components.footer.data_privacy" | translate}}
            </a>
        </div>
        <div>
            <a vcldAutomationId="consr_app-root_terms-and-condition_link" [href]="terms" target="_blank">
                {{ "components.footer.terms_conditions" | translate }}
            </a>
        </div>
        <div>
            <a vcldAutomationId="consr_app-root_cookie-preferance_link" onclick="OneTrust.ToggleInfoDisplay()">
                {{ 'components.footer.cookies' | translate }}
            </a>
        </div>
    </div>
</div>
