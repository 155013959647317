import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { ConsumerAppEnvironment as environment } from 'visenvironment';

export enum InteractionType {
    VTO_SESSION,
    USER_LOGIN,
    TC_FAVORITE,
    TC_ORDER,
    TC_TRY_ON
}

export abstract class Interaction {
    type: InteractionType;
    createdAt: Date;
}

export class VTOSessionInteraction extends Interaction {
    sessionId: string;
    opticianId: string;
}

export class LoginInteraction extends Interaction {
    userId: number;
}

export class TintCoatingInteraction extends Interaction {
    frameId: string;
    tintId: string;
    coatingId: string;
    sessionId: string;
    userId?: string;
}
@Injectable({ providedIn: 'root' })
export class StatisticService {

    constructor(private _http: HttpClient, private _outh: OAuthService) { }


    public log(interactionPayload: Interaction): void {
        const url = environment.connectivity.statisticsEndpoint;
        this._http.post(url, interactionPayload, { headers: { Authorization: `Bearer ${this._outh.getIdToken()}` } })
            .toPromise()
            .catch((error) => console.error(error));
    }
} 