// import { ColorVariant } from './colorVariant.model';

import { CoatingObj, TintObj } from "../../configs/lensSettings.mock";

export enum FrameAvailability {
    AVAILABLE, 
    PERMANENTLY_NOT_AVAILABLE,
    TEMPORARY_NOT_AVAILABLE
}

export class ColorVariant {

    public id: string;

    public color: string;
}

export interface ObsoleteFrames {
    favorites: string[],
    recommendations: string[]
}

export class Frame {

    public id: string;

    public available: boolean;

    public availabilityStatus: FrameAvailability | string ;

    public availableAgainFrom: Date;

    public brand: string;

    public material: string;

    public retailPrice: number;

    public currency: string;

    // Obsolete
    public designId: string;
    // Obsolete
    public frameId: string;

    public color: string;
    public glassTypeEligibility: string;

    // Useless
    public meshLink: string;

    // Useless
    public imageLink: string;

    public model: string;

    public frameSize: string;

    public eyeSize: number;

    public bridgeSize: number;

    public frameType: string;

    public shapeType: string;

    public rimType: string;

    public style: string;

    public templeLength: number;

    public isColorful: boolean;

    public isDark: boolean;

    public isLight: boolean;

    public variants: Array<ColorVariant>;

    public targetGroup: string;

    public tint?: TintObj = null;

    public coating?: CoatingObj = null;
}
