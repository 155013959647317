import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ErrorHandler } from '@angular/core';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, of } from 'rxjs';
import {  map,tap,switchMap,catchError } from 'rxjs/operators';
import { ConsumerAppEnvironment as environment } from 'visenvironment';
import { ApplicationInsightsService } from '../../services/applicationInsights.Service';
import { DebugService } from '../../services/debug.service';
import { AvatarCreationSession, FavoriteFrame } from '../models/avatarcreationsession';
import { FeatureFlagsService } from '../../services/featureFlag.service';

export enum DataType {
    SESSIONS,
    ECP_SETTINGS,
    TINTS_COATINGS,
    FRAME_PROBABILITY,
    FRAME_CATALOGUE_OPTICIAN,
}
export enum BinaryType {
    AVATAR,
    AVATAR_THUMBNAIL,
    AVATAR_TEXTURE,
    AVATAR_METADATA,
    FRAME,
    FRAME_THUMBNAIL,
    COLOR_THUMBNAIL,
    ECP_LOGO,
    MATERIAL_LIBRARY,
    MODEL,
    ANNOTATION,
    ALBEDO,
    NORMAL,
    ORM,
    LENSES_MODEL  
}
export type HttpOptions = {
    headers?: HttpHeaders | {[header: string]: string | string[]},
    observe?: 'body',
    params?: HttpParams|{[param: string]: string | string[]},
    reportProgress?: boolean,
    responseType?: 'json',
    withCredentials?: boolean,
};


@Injectable({providedIn: 'root'})
export class DataService {

    private readonly baseAvatarUrl = environment.connectivity.esbBaseUrl;
    
    private readonly baseFrameUrl = environment.connectivity.gfdbCDNConnectionEndpoint;
    private readonly frontdoorApiBaseUrl = environment.connectivity.frontdoorApiBaseUrl;
    private readonly esbBaseFrameUrl = this.frontdoorApiBaseUrl ? this.frontdoorApiBaseUrl + '/frame/620681' :environment.connectivity.esbBaseUrl + '/frame/620681';
    private readonly framesImageUrl = this.frontdoorApiBaseUrl ? this.frontdoorApiBaseUrl + '/frames' :environment.connectivity.framesEndpoint;

    private readonly FAVORITE_PRELOAD_COUNT: number = 24;
    private readonly cdnImplementedBinarytypes: BinaryType[] = new Array(
        BinaryType.FRAME,
        BinaryType.FRAME_THUMBNAIL,
        BinaryType.COLOR_THUMBNAIL,
        BinaryType.MATERIAL_LIBRARY,
        BinaryType.MODEL,
        BinaryType.ANNOTATION,
        BinaryType.ALBEDO,
        BinaryType.NORMAL,
        BinaryType.ORM,
        BinaryType.LENSES_MODEL);

    constructor (
        private _http: HttpClient, 
        private _oauth: OAuthService,
        private _debug: DebugService,
        private readonly _insight: ApplicationInsightsService,
        private readonly _errorHandler: ErrorHandler,  
        private features: FeatureFlagsService      
        ) {}

    /** * Contains the already made requests     
     * * Cache them during application runtime     
     * */    
    private cache: Map<string, Map<string, Observable<any>>> = new Map<string, Map<string, Observable<any>>>([
        ["SESSIONS", new Map<string, Observable<any>>()],
        ["ECP_SETTINGS", new Map<string, Observable<any>>()],
        ["TINTS_COATINGS", new Map<string, Observable<any>>()],
        ["FRAME_PROBABILITY", new Map<string, Observable<any>>()],
        ["FRAME_CATALOGUE_OPTICIAN", new Map<string, Observable<any>>()],
        ["AVATAR", new Map<string, Observable<string>>()],
        ["AVATAR_THUMBNAIL", new Map<string, Observable<string>>()],
        ["AVATAR_TEXTURE", new Map<string, Observable<string>>()],
        ["AVATAR_METADATA", new Map<string, Observable<string>>()],
        ["FRAME", new Map<string, Observable<string>>()],
        ["FRAME_THUMBNAIL", new Map<string, Observable<string>>()],
        ["COLOR_THUMBNAIL", new Map<string, Observable<string>>()],
        ["ECP_LOGO", new Map<string, Observable<string>>()],
        ["MATERIAL_LIBRARY", new Map<string, Observable<string>>()],
        ["MODEL", new Map<string, Observable<string>>()],
        ["ANNOTATION", new Map<string, Observable<string>>()],
        ["ALBEDO", new Map<string, Observable<string>>()],
        ["NORMAL", new Map<string, Observable<string>>()],
        ["ORM", new Map<string, Observable<string>>()],
        ["LENSES_MODEL", new Map<string, Observable<string>>()]
    ]);

    /**     
     * * Search in the cache for the given data type and identifier or downloads it from the API     
     * * Auomatically caches the data once downloaded     
     * * @param dataType The Data to be requested - used for the Cache Map as a key     
     * * @param identifier The Id of the requested object - also identifies the cache entry and will be used for downloading the correct resource     
     * * @param requestParams Additional Request Parameters - can be used to overwrite the default ones     
     * * @returns The Value {T} from Cache or API     */   
     public async getData<T>(dataType: DataType , identifier: string, requestParams?: HttpOptions): Promise<T> {
            const cacheEntry = this.cache.get(DataType[dataType]);
            const cacheValue = cacheEntry?.get(identifier);
            if (!cacheValue) {
                const resp$ =  this.downloadDataFromAPI<T>(dataType, identifier, requestParams);
                cacheEntry?.set(identifier,resp$);
                return resp$.toPromise();
            }
    
            this._debug.log(`%c[DataService] %crequesting %c${DataType[dataType]}%c... => %cCached`, 'font-weight: bold; color: black;', 'font-weight: thin; color: #2D2D2D;', 'font-style: italic;', 'font-style: normal; color: #2D2D2D;', 'color: #04B45F;');
            
            return cacheValue.toPromise<T>();
    }

    /**     
     * * Search in the cache for the given data type and identifier or downloads it from the API     
     * * Auomatically caches the data once downloaded     
     * * @param binaryType The binary to be requested - used for the Cache Map as a key     
     * * @param identifier The Id of the requested object - also identifies the cache entry and will be used for downloading the correct resource     
     * * @param requestParams Additional Request Parameters - can be used to overwrite the default ones     
     * * @returns The Value {T} from Cache or API     */   
    public async getBinary(binaryType: BinaryType, identifier: string, ignoreError?: boolean,requestParams?: any): Promise<string> {
        const cacheEntry = this.cache.get(BinaryType[binaryType]);
        const cacheValue = cacheEntry?.get(identifier);
        if (!cacheValue) {
            const resp$ =  this.downloadBinaryFromAPI(binaryType, identifier,ignoreError,requestParams);
            cacheEntry?.set(identifier,resp$);
            return resp$.toPromise();        
        }

        this._debug.log(`%c[DataService] %crequesting %c${BinaryType[binaryType]}${identifier}%c... => %cCached`, 'font-weight: bold; color: black;', 'font-weight: thin; color: #2D2D2D;', 'font-style: italic;', 'font-style: normal; color: #2D2D2D;', 'color: #04B45F;');
        
        return cacheValue.toPromise();
        
       
    }

    /**     
     * * Internal Download function from downloading data form the API   
     * * @param dataType The Data to be requested - used for the Cache Map as a key     
     * * @param identifier The Id of the requested object - also identifies the cache entry and will be used for downloading the correct resource     
     * * @param requestParams Additional Request Parameters - can be used to overwrite the default iones     
     * * @returns The Value {T} from Cache or API     */   
     private downloadDataFromAPI<T>(dataType: DataType , identifier: string, requestParams?: HttpOptions): Observable<T> {
        this._debug.log(`%c[DataService] %cdownloading %c${this.getUrlData(dataType,identifier)}%c... => %cPending`, 'font-weight: bold; color: black;', 'font-weight: thin; color: #2D2D2D;', 'font-style: italic;', 'font-style: normal; color: #2D2D2D;', 'color: #FFBF00;');   
        return this._http.get<T>(this.getUrlData(dataType,identifier),{
            ...this.getHeaders(requestParams)
        });
    }

    /**     
     * * Internal Download function from downloading data form the API   
     * * @param binaryType The Data to be requested - used for the Cache Map as a key     
     * * @param identifier The Id of the requested object - also identifies the cache entry and will be used for downloading the correct resource     
     * * @param requestParamsBlob Additional Request Parameters - can be used to overwrite the default iones     
     * * @returns The Value {T} from Cache or API     */   
        private downloadBinaryFromAPI(binaryType:  BinaryType, identifier: string,ignoreError?: boolean,params?: any): Observable<string|null> {  
            var isCDNEnabled:boolean=this.features.isFeatureFlagEnabled('CDNEnabled');  
            this._debug.log(`%c[DataService] %cdownloading %c${this.getUrlBinary(binaryType,identifier,isCDNEnabled)}%c... => %cPending`, 'font-weight: bold; color: black;', 'font-weight: thin; color: #2D2D2D;', 'font-style: italic;', 'font-style: normal; color: #2D2D2D;', 'color: #FFBF00;');      
            return this._http.get(this.getUrlBinary(binaryType,identifier,isCDNEnabled),{
                headers: { Authorization: `Bearer ${this._oauth.getIdToken()}` },
                responseType: 'blob'
            }).pipe( catchError((e) => {
                if (!ignoreError) {
                    this._errorHandler.handleError(e);
                }
                return of(null);
            }),
                switchMap((blob) => {
                    let url: string;
                    if (blob == null && this.cdnImplementedBinarytypes?.includes(binaryType) && isCDNEnabled) {
                            switch (binaryType) {
                                case BinaryType.FRAME:
                                    url = `${this.esbBaseFrameUrl}/${identifier}/Model.gltf`;
                                    break;
                                case BinaryType.COLOR_THUMBNAIL:
                                    url = `${this.esbBaseFrameUrl}/${identifier}/colorthumbnail.png`;
                                    break;
                                case BinaryType.FRAME_THUMBNAIL:
                                    url = `${this.framesImageUrl}/${identifier}/image`;
                                    break;
                                case BinaryType.MATERIAL_LIBRARY:
                                        url = `${this.esbBaseFrameUrl}/${identifier}/Model.mtl`;
                                        break;
                                case BinaryType.MODEL:
                                    url = `${this.esbBaseFrameUrl}/${identifier}/Model.bin`;
                                    break;
                                case BinaryType.ANNOTATION:
                                    url = `${this.esbBaseFrameUrl}/${identifier}/Annotation.json`;
                                    break;
                                case BinaryType.ALBEDO:
                                    url = `${this.esbBaseFrameUrl}/${identifier}/Albedo.jpg`;
                                    break;
                                case BinaryType.NORMAL:
                                    url = `${this.esbBaseFrameUrl}/${identifier}/Normal.png`;
                                    break;
                                case BinaryType.ORM:
                                    url = `${this.esbBaseFrameUrl}/${identifier}/ORM.png`;
                                    break;
                                case BinaryType.LENSES_MODEL:
                                    url = `${this.esbBaseFrameUrl}/${identifier}/LensesModel.glb`;
                                    break;
                                default:
                                    return of (null);
                            }
                            return this._http.get(url, {
                                headers: { Authorization: `Bearer ${this._oauth.getIdToken()}` },
                                responseType: 'blob',params},
                                ).pipe(catchError((e) => {
                                    if (!ignoreError) {
                                        this._errorHandler.handleError(e);
                                    }
                                    return of(null);
                                }),
                                    map(blob => 
                                    { 
                                        if (blob == null) {
                                            return null;
                                        }   
                                        else
                                            return URL.createObjectURL(blob);
                                    })
                                );
                        }
                    else if (blob == null) {
                        return of (null);
                    }
                    else {
                            return of (URL.createObjectURL(blob));
                        }
                    
                    
                })
                
            );
            
        }

    /**
     * * Get the URL for the Given DataType to download fform API    
     * * @param dataType The Data to be requested - used for the Cache Map as a key     
     * * @returns URL for given DataType     
     * */    
    private getUrlData(dataType: DataType ,id:string) {
        if(dataType in DataType){
            switch(dataType) {
                case DataType.SESSIONS:
                    return `${environment.connectivity.avatarSessionEndpoint}`;
                case DataType.ECP_SETTINGS:
                    return `${environment.connectivity.ecpSettings.replace("{opticianId}",id)}`;
                case DataType.TINTS_COATINGS:
                    return `${environment.connectivity.tintsAndCoatingEndpoint}`;
                case DataType.FRAME_PROBABILITY:
                    return `${environment.connectivity.recommendationsEndpoint}/${id}`;
                case DataType.FRAME_CATALOGUE_OPTICIAN:
                    return `${environment.connectivity.framesEndpoint}/${id}`;
                default:
                    return '';
            }
        }
    }
    /**
     * * Get the URL for the Given DataType to download fform API    
     * * @param binaryType The Data to be requested - used for the Cache Map as a key     
     * * @returns URL for given DataType     
     * */    
    private getUrlBinary(binaryType:  BinaryType,id:string,isCDNEnabled:boolean) {
        switch(binaryType) {
            case BinaryType.AVATAR:
                const meshApiUrl = this.frontdoorApiBaseUrl || this.baseAvatarUrl;
                return `${meshApiUrl}/${id}/Mesh`;
            case BinaryType.AVATAR_TEXTURE:
                const textureApiUrl = this.frontdoorApiBaseUrl || this.baseAvatarUrl;
                return `${textureApiUrl}/${id}/Texture`;
            case BinaryType.AVATAR_METADATA:
                const metadataApiUrl = this.frontdoorApiBaseUrl || this.baseAvatarUrl;
                return `${metadataApiUrl}/${id}/Metadata`;                   
            case BinaryType.FRAME:
                return isCDNEnabled ? `${this.baseFrameUrl}/${id}/MOBILE/MODEL.GLTF` : `${this.esbBaseFrameUrl}/${id}/Model.gltf`;                  
            case BinaryType.AVATAR_THUMBNAIL:
                return `${this.baseAvatarUrl}/${id}/thumbnail`;                    
            case BinaryType.COLOR_THUMBNAIL:
                return isCDNEnabled ? `${this.baseFrameUrl}/${id}/OTHERS/BASICCOLORTHUMBNAIL.PNG` : `${this.esbBaseFrameUrl}/${id}/colorthumbnail.png`;                   
            case BinaryType.ECP_LOGO:
                return `${this.baseAvatarUrl}/branding/logo/${id}`;                    
            case BinaryType.FRAME_THUMBNAIL:
                return isCDNEnabled ? `${this.baseFrameUrl}/${id}/INSTORE/THUMBNAIL.PNG` : `${this.framesImageUrl}/${id}/image`;         
            case BinaryType.MATERIAL_LIBRARY:
                return isCDNEnabled ?`${this.baseFrameUrl}/${id}/MOBILE/MODEL.MTL` : `${this.esbBaseFrameUrl}/${id}/Model.mtl`;             
            case BinaryType.MODEL:
                return isCDNEnabled ? `${this.baseFrameUrl}/${id}/MOBILE/MODEL.BIN` : `${this.esbBaseFrameUrl}/${id}/Model.bin`;
            case BinaryType.ANNOTATION:
                return isCDNEnabled ? `${this.baseFrameUrl}/${id}/COMMON/ANNOTATION.JSON` : `${this.esbBaseFrameUrl}/${id}/Annotation.json`;
            case BinaryType.ALBEDO:
                return isCDNEnabled ? `${this.baseFrameUrl}/${id}/MOBILE/ALBEDO.JPG` : `${this.esbBaseFrameUrl}/${id}/Albedo.jpg`;
            case BinaryType.NORMAL:
                return isCDNEnabled ? `${this.baseFrameUrl}/${id}/MOBILE/NORMAL.PNG` : `${this.esbBaseFrameUrl}/${id}/Normal.png`;
            case BinaryType.ORM:
                return isCDNEnabled ? `${this.baseFrameUrl}/${id}/MOBILE/ORM.JPG` : `${this.esbBaseFrameUrl}/${id}/ORM.png`;
            case BinaryType.LENSES_MODEL:
                return isCDNEnabled ? `${this.baseFrameUrl}/${id}/COMMON/LENSESMODEL.GLB` :`${this.esbBaseFrameUrl}/${id}/LensesModel.glb` ;
            default:
                return '';
            }
        
    }

    /**     * Default Http Headers for API calls     
     * * @param requestParams Additional Rquest Params - can be used to overwrite the default     
     * * @returns HttpOptions     
     * */    
    private getHeaders(requestParams?: HttpOptions): HttpOptions  {
        return {...requestParams?.params, headers: {...requestParams?.headers,authorization: `Bearer ${this._oauth.getIdToken()}`}}
    }


    public async ngOnDestroy() {
        for (const binaryMap of this.cache.values()) {
            for (const item of binaryMap.entries()) {
                if (item[1] !== null) {
                    URL.revokeObjectURL(await item[1].toPromise());
                }
                binaryMap.delete(item[0]);
            }
        }
    }
    

    public async preloadAvatarWithFavorites(session: AvatarCreationSession) {
        this._debug.log(`[DataService] Fetch avatar with favorites => pending`);

        const start = performance.now();

        await Promise.all([
            this.getBinary(BinaryType.AVATAR,session.id),
            this.getBinary(BinaryType.AVATAR_TEXTURE,session.id),
            this.getBinary(BinaryType.AVATAR_METADATA,session.id)
        ]);

        const all = [];

        const favorites = session.favoritedFrames.slice(0, this.FAVORITE_PRELOAD_COUNT);

        favorites.forEach(frame => {
            all.push(this.getBinary( BinaryType.FRAME,frame.frameId,true));
        });
        
        await Promise.all(all);

        const time = Math.floor(performance.now() - start);
        this._debug.log(`[DataService] Fetch avatar with ${session.favoritedFrames ? session.favoritedFrames.length : 0} favorites took ${time}ms (${time / 1000}s)`);
        this._insight.logMetric('fetch_avatar_with_fravorites', time);
    }

    public invalidateCacheEntry(id: string, type: string) {
        const cacheMap = this.cache.get(type);
        cacheMap.delete(id);
    }
    
    public setCacheEntryAsync(id: string, type: string, item: string): Promise<void> {
        return new Promise((resolve) => {
            const cacheMap = this.cache.get(type);
            cacheMap.set(id, of(item));
            resolve(void (0));
        });
    }

    public setAvatarThumbnail(sessionId: string, image: Blob) {
        const form: FormData = new FormData();
        form.append("content", image);
        const url = `${this.baseAvatarUrl}/${sessionId}/thumbnail`;
        return this._http.put(url, form, {
            headers: {
                Authorization: `Bearer ${this._oauth.getIdToken()}`,
            },
        });
    }
}