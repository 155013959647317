import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { AdminAppEnvironment, ConsumerAppEnvironment as environment } from 'visenvironment';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { EmailService } from '../../../services/email.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { ZeissIdToken, ZeissIdBase } from 'visauto-auth';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { GoogleTagManagerService, GTMCustomEvents } from '../../services/gtm.service';
import { MatDialogRef } from '@angular/material/dialog';
import { SnackbarComponent } from '../../../components/snackbar/snackbar.component';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lib-contact-ecp-card',
  templateUrl: './contact-ecp-card.component.html',
  styleUrls: ['./contact-ecp-card.component.scss'],
})
export class ContactECPCardComponent implements OnInit {
  isLoading = false;

  // eslint-disable-next-line max-len
  supportMailField = new UntypedFormControl(
    { value: AdminAppEnvironment.contact.zeiss_support, disabled: true },
    [Validators.required, Validators.email]
  );
  messageField = new UntypedFormControl('', [Validators.required]);
  userMailField = new UntypedFormControl('', [Validators.required, Validators.email]);
  priorityField = new UntypedFormControl('', [Validators.required]);

  constructor(
    private translate: TranslateService,
    private auth: OAuthService,
    private email: EmailService,
    private snack: MatSnackBar,
    private gtm: GoogleTagManagerService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public dialogData,
    public dialogRef: MatBottomSheetRef<ContactECPCardComponent>,
  ) {
  }

  ngOnInit() {
    this.userMailField.setValue(this.getUserEmail());
    this.priorityField.setValue(0);
    this.gtm.pageView('/contact-ecp');
  }

  public onSubmit() {
    if (this.isFormValid()) {
      this.isLoading = true;

      const url = environment.connectivity.email_endpoint + '/' + this.dialogData.target;
      const opticianId = this.dialogData.session ? this.dialogData.session.opticianId : null;

      const emailObject: SupportEmailModel = {
        comment: this.messageField.value,
        opticianId,
        language: this.translate.currentLang
      };

      this.email.sendEmail(emailObject, url).subscribe((data) => {
        if (data instanceof HttpErrorResponse) {
          this.isLoading = false;
          this.snack.openFromComponent(SnackbarComponent, {
            data: {
              icon: 'info',
              heading: "errors.sorry",
              message: "components.contact_ecp_card.error",
              btnIcon: 'close',
              support: false
            },
            panelClass: 'error-panel',
            verticalPosition: 'top',
            horizontalPosition: 'center',
            duration: 2000
          });
        } else {
          this.isLoading = false;
          this.messageField.updateValueAndValidity();
          this.snack.openFromComponent(SnackbarComponent, {
            data: {
              icon: 'check',
              heading: "errors.success",
              message: "components.contact_ecp_card.successfully_sent",
              btnIcon: 'close',
              support: false
            },
            panelClass: 'error-panel',
            verticalPosition: 'top',
            horizontalPosition: 'center',
            duration: 2000
          });
          this.dialogRef.dismiss();
        }
      });

      this.gtm.pushEvent(GTMCustomEvents.contact_ecp_submit);
    }
  }

  private getUserEmail() {
    const claims = this.auth.getIdentityClaims() as ZeissIdToken;
    if (claims) {
      const base = JSON.parse(claims.ZeissIdBase) as ZeissIdBase;
      return base.eMail;
    }

    return '';
  }

  public close(): void {
    this.dialogRef.dismiss();
  }

  private isFormValid() {
    return (
      !this.supportMailField.invalid &&
      this.messageField.valid &&
      this.userMailField.valid &&
      this.priorityField.valid
    );
  }

  private constructEmailForm() {

    return {
      Subject: 'VISUCLOUD Support Request',
      MessageBody: this.messageField.value,
      MessageBodyPlainText: this.messageField.value,
      Recipients: [this.supportMailField.value],
      CC: [],
      From: 'noreply@zeiss.com',
      Sender: 'VISUCLOUD',
      ReplyTo: this.userMailField.value,
      Priority: this.translatePriority(this.priorityField.value),
      ErrorReportDetails: true,
      Attachments: [],
    };
  }

  private translatePriority(priority: number) {
    switch (priority) {
      case 0:
        return 'Low';

      case 1:
        return 'Normal';

      case 2:
        return 'High';
    }
  }
}


export class SupportEmailModel {

  public opticianId?: string;

  public comment: string;

  public language: string;
}
