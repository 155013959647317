<div class="dialog-header">
    <p mat-dialog-title class="headline-wrapper bold" vcldAutomationId="consr_vto_vis-privacy-dialog_headline-privacy_hdr">{{ 'components.dialogs.data_privacy.headline' | translate }}</p>
    <button mat-icon-button class="close-btn" [mat-dialog-close]="true">
        <mat-icon vcldAutomationId="consr_vto_vis-privacy-dialog_close_icon">close</mat-icon>
    </button>
</div>

<div mat-dialog-content>
    <p class="bold">{{ 'components.dialogs.data_privacy.general_statement' | translate }}</p>
    <div class="text-wrapper">
        <a [href]="dataPrivacyLink" target="_blank">
            {{ 'components.dialogs.data_privacy.data_privacy_link_name' | translate }}
        </a>
    </div>
    <p class="subline-wrapper bold">{{'components.dialogs.data_privacy.addition_headline' | translate}}</p>
    <p class="text-wrapper" featureFlag="EmailInvitationEnabled">{{'components.dialogs.data_privacy.addition_block_1' |
        translate }}</p>
    <p class="text-wrapper" featureFlag="SMSInvitationEnabled">{{'components.dialogs.data_privacy.addition_block_1_sms'
        | translate }}</p>
    <p class="text-wrapper">{{ 'components.dialogs.data_privacy.addition_block_2_1' | translate }}
        <a [href]="withdrawal" target="_blank">{{'components.dialogs.data_privacy.withdrawal_name' | translate}}</a>
    </p>
    <p>{{'components.dialogs.data_privacy.addition_block_2_2' | translate}}</p>
</div>