import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { TintObj } from '../../../configs/lensSettings.mock';
import { Frame } from '../../models/frame.model';
import { TintsCoatingsService } from '../../services/tints-coatings.service';

@Component({
  selector: 'app-tint-host',
  templateUrl: './tint-host.component.html',
  styleUrls: ['./tint-host.component.scss']
})
export class TintHostComponent implements OnInit {

  @Input()
  public tint: TintObj;

  @Input()
  public selectedFrame$: BehaviorSubject<Frame>;

  @Input()
  public selectedTint$: BehaviorSubject<string> = null;

  @Input()
  public selectedCoating$: BehaviorSubject<string> = null;

  @Output()
  public onTintClick: EventEmitter<string> = new EventEmitter();

  public translationObjName: string = 'localization';
  public tintIdPrefix: string = 'tint_';
  public thumbnail;

  constructor( public _tintsCoatings: TintsCoatingsService, public translate: TranslateService, private _sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  selectTints(catalogCode) {
    this.onTintClick.emit(catalogCode);
  }

  ngOnChanges() {
    if (this.tint?.thumbnail) this.thumbnail = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/svg+xml;base64, ' + this.tint?.thumbnail);
  }
  
  public hasTintRestrictions(tint: TintObj): boolean {
    if (this.selectedCoating$.getValue()) {
        const t = this._tintsCoatings.getLensByCatalogCode(this.selectedCoating$.getValue());
        if (t && t.tintRestrictions)
            return t.tintRestrictions?.includes(tint?.vcldTintGroupId);
        return false;    
    }

    if(!this.selectedFrame$.getValue()) return true;

    return false;
  }
}
