import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggerService, LogSeverity } from '../services/logger.service';

@Injectable({ providedIn: 'root' })
export class CacheService {

    private _cache: Map<string, HttpResponse<any>> = new Map<string, HttpResponse<any>>();

    constructor(private _log: LoggerService) { }

    public get(key: string): HttpResponse<any> | null {
        if (this._cache.has(key)) {
            this._log.log(LogSeverity.CACHE_READ, `Read '${key}' from cache - OK`);
            return this._cache.get(key);
        }

        this._log.log(LogSeverity.CACHE_NOT_FOUND, `Cache '${key}' not found - 404`);
        return null;
    }

    public put(key: string, value: HttpResponse<any>) {
        this._log.log(LogSeverity.CACHE_ADD, `Add '${key}' to Cache - OK`);
        this._cache.set(key, value);
    }
}
