<div vcldAutomationId="consr_app-coating-host_coating-type_tile" (click)="selectCoating(coating.catalogCode)" [id]="coating.catalogCode" class="col s12 platinum lens-tile"
    [ngClass]="{
        'disabled-combination': (selectedFrame$ | async) === null, 
        'selectedLens': (selectedCoating$ | async) == coating.catalogCode
    }">

    <img *ngIf="thumbnail; else fallbackIcon" [src]="thumbnail" class="filter-icon visucloud-icons">

    <ng-template #fallbackIcon>
        <i class="filter-icon" [class]="_tintsCoatings.getCoatingIcon(coating)"></i>
    </ng-template>
    <p vcldTooltip>{{ coating | tintCoatingName: translationObjName }}</p>
</div>