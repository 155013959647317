import { Directive, ElementRef, Input, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { FeatureFlagsService } from "../services/featureFlag.service";

@Directive({
    selector: "[featureFlag]",
  })
  export class FeatureFlagDirective implements OnInit {

    private requiredFlag: string = "";

    private isHidden = true;

    @Input() set featureFlag(val) {
      if (val) {
        this.requiredFlag = val;
        this.updateView();
      }
    }
    constructor(
      private _featureFlags: FeatureFlagsService,
      private _element: ElementRef,
    ) {}
    
    ngOnInit() {
      this.updateView();
    }

    private updateView() {
      if (this.checkValidity()) {
        if (this.isHidden) {
        this._element.nativeElement.style.display = "inherit";
          this.isHidden = false;
        }
      } else {
        this._element.nativeElement.style.display = "none";
        this.isHidden = true;
      }
    }
    private checkValidity() {
      return (
        this.requiredFlag &&
        this._featureFlags.isFeatureFlagEnabled(this.requiredFlag)
      );
    }
  }