import { Inject, Component } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService, GTMLayer } from '../../vto/services/gtm.service';
import { ConsumerAppEnvironment as environment } from 'visenvironment';
import { AppConfigService } from '../../services/appConfig.service';


@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'vis-privacy-dialog',
    templateUrl: 'privacy.dialog.component.html',
    styleUrls: ['privacy.dialog.component.scss']
})
export class PrivacyDialogComponent {

    private transformLanguage: string;


    constructor(
        private dialogRef: MatDialogRef<PrivacyDialogComponent>,
        private gtm: GoogleTagManagerService,
        private translate: TranslateService,
        private appConfig: AppConfigService
    ) { }

    public get dataPrivacyLink(): string {

        // get selected language and replace '-' with '_' so that key can match with links.
        this.transformLanguage = this.translate.currentLang.replace('-', '_');

        // check if link exists for 'data_protection' for selected language.
        if (this.appConfig.appLinks['data_protection_' + this.transformLanguage])
            return this.appConfig.appLinks['data_protection_' + this.transformLanguage];

        //if link doesn't exists for 'data_protection', then return en_US as default link.
        return this.appConfig.appLinks['data_protection_en_US'];
    }

    public get withdrawal(): string {
        if (this.translate.currentLang == 'de-DE') {
            return this.appConfig.appLinks['withdrawal_de_DE'];
        }else if(this.translate.currentLang == 'ja-JP') {
            return this.appConfig.appLinks['withdrawal_ja_JP'];
        }
        //if link doesn't exists for 'withdrawal', then return en_US as default link.
        return this.appConfig.appLinks['withdrawal_en_US'];
    }
}
