import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, ErrorHandler } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { ApplicationInsightsService } from '../services/applicationInsights.Service';
import { ConsumerAppEnvironment as environment } from 'visenvironment';
import { OAuthService } from 'angular-oauth2-oidc';
import { SnackbarComponent } from '../components/snackbar/snackbar.component';

@Injectable()
export class ConsumerErrorHandler implements ErrorHandler {

    private readonly applicationCode: string = 'C';

    private currentUrl: string = '';
    private previousUrl: string = '';

    private readonly debugFlag = localStorage.getItem('vcld_debug') == 'true';

    private occuredErrors: Array<string> = [];

    private heading: string;
    private message: string;

    constructor(
        private snack: MatSnackBar,
        private router: Router,
        private appInsights: ApplicationInsightsService,
        private translate: TranslateService,
        private _oauth: OAuthService
    ) {
        this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((navigation: NavigationEnd) => {
            this.previousUrl = (this.currentUrl == '' ? navigation.url : this.currentUrl);
            this.currentUrl = navigation.url;
        });
    }

    handleError(error: Error): void {
        if (!this._oauth.hasValidIdToken()) return;

        if (environment.production || !this.debugFlag) {
            const pageCode = this.getPageCode();
            let errorCode = '';
            let functionCode = '';

            if (error instanceof HttpErrorResponse) {
                const e = error as HttpErrorResponse;
                errorCode = this.getHttpErrorCode(e);
                functionCode = this.getHttpErrorFunction(e);
            } else {
                errorCode = '9';
                functionCode = this.getApplicationErrorFunction(error);
            }

            const visErrorCode = `${this.applicationCode}${pageCode}${functionCode}${errorCode}`;

            if(visErrorCode == 'CP#9') return;

            /* 
             Connection Error - 400 
             Generic Error - 500 & 404
             Reload --> Generic Error - 401 & 402 & 9 (unknown)
             */

            /* 
            HTTP 400
            Display connection error 
            */
            if (errorCode == '0' || functionCode == 'z') {
                this.connectionSnack(visErrorCode);

                /* 
                HTTP 500
                HTTP 404
                Display generic error message
                */
            } else if (errorCode == '5' || errorCode == '4') {
                this.supportSnack(error, visErrorCode);

                /* 
                HTTP 401
                HTTP 402
                ErrorCode 9 (unknown)
                Display reload message if error code occured for the first time 
                Display generic error message if error code occures another times
                */
            } else if (errorCode == '1' || errorCode == '2' || errorCode == '9') {
                if (sessionStorage.getItem('vcld_error')) {
                    this.occuredErrors = [...JSON.parse(sessionStorage.getItem('vcld_error'))];
                }

                if (!this.occuredErrors.includes(visErrorCode)) {
                    this.occuredErrors.push(visErrorCode);
                    sessionStorage.setItem('vcld_error', JSON.stringify(this.occuredErrors));
                    this.reloadSnack(visErrorCode);
                } else {
                    this.supportSnack(error, visErrorCode);
                }
            }

            console.error(this.heading + this.message + this.translate.instant('errors.support'), visErrorCode);

        } else {
            this.snack.open(`${error.name}: ${error.message}`, 'FML');
            console.error(error);
        }
    }

    private getHttpErrorCode(error: HttpErrorResponse): string {
        const s = error.status;

        switch (s) {
            case 400:
                return '0';

            case 401:
                return '1';

            case 404:
                return '4';

            case 500:
                return '5';

            default:
                return '2';
        }
    }

    private getHttpErrorFunction(error: HttpErrorResponse): string {
        const s = error.url.split('/').map(s => s.toLowerCase());

        let f = s.pop();
        let f2 = s.pop();
        let f3 = s.pop();

        if (f2 == 'recommendations' || f2 == 'frames') {
            f = f2;
        }

        if (f2 == 'lastaccess') {
            f = f2;
        }

        if (f3 == 'frames') {
            f = f3;
        }

        switch (f) {
            case 'sessions':
                return 'b';
            case 'consumersessions':
                return 'b';
            case 'lastaccess':
                return 'y';
            case 'mesh':
                return 'c';
            case 'recommendations':
                return 'e';
            case 'model.obj':
                return 'e';
            case 'thumbnail':
                return 'e';
            case 'frames':
                return 't';
            case 'fps':
                return 'm';
            case 'colorthumbnail.png':
                return 'e';
            case 'lensesmodel.obj':
                return 'g';
            case 'select-frame':
                return 'r';
            case 'ecp':
                return 's';
        }
        return f;
    }

    private getApplicationErrorFunction(error: Error): string {

        if (error.message.toLowerCase().startsWith('uncaught (in promise): loadmodelfailed')) {
            return 'c';
        }

        if (error.message.toLowerCase().startsWith('uncaught (in promise): error: widget is already paused.')) {
            return 'p'
        }

        if (error.message.toLowerCase().startsWith('uncaught (in promise): httperrorresponse:')) {
            return 'z';
        }

        return '#';
    }

    private getPageCode(): string {
        const s = this.currentUrl.split('/');
        let page = s.length >= 2 ? s[1].toLowerCase() : '';
        page = page.split('?')[0];

        switch (page) {
            case 'profile':
                return 'P';

            case 'viewer':
                return 'V';

            case 'order':
                return 'S';

            case 'gallery':
                return 'F';

            default:
                return 'G';
        }
    }

    private logAppInsights(error: Error, visErrorCode: string, msg: string): void {
        this.appInsights.logException(error, { visucloudErrorCode: visErrorCode, message: msg });
    }

    private supportSnack(error: Error, visErrorCode: string) {
        this.heading = 'errors.sorry';
        this.message = 'errors.try_again';
        this.logAppInsights(error, visErrorCode, this.message);

        this.openCustomSnackbar(true, visErrorCode);
    }

    private reloadSnack(visErrorCode: string) {
        this.heading = 'errors.sorry';
        this.message = 'errors.reload';

        this.openCustomSnackbar(false, visErrorCode);
    }

    private connectionSnack(visErrorCode: string) {
        this.heading = 'errors.information';
        this.message = 'errors.connection_error';

        this.openCustomSnackbar(false, visErrorCode);
    }

    private openCustomSnackbar(support: boolean, visErrorCode: string) {
        if (visErrorCode != 'CPe4' && visErrorCode != 'CGe4' && visErrorCode != 'CVe4' && visErrorCode != 'CFe4') {
            this.snack.openFromComponent(SnackbarComponent, {
                data: {
                    icon: 'info',
                    heading: this.heading,
                    message: this.message,
                    btnIcon: 'close',
                    support: support
                },
                panelClass: 'error-panel',
                verticalPosition: 'top',
                horizontalPosition: 'center'
            });
        }
    }

}