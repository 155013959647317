import { HttpClient } from '@angular/common/http';
import { Injectable} from '@angular/core';
import { Subject } from 'rxjs';
import { ConsumerAppConfig } from '../types/appConfig';
import { ConsumerAppEnvironment as environment } from 'visenvironment';

@Injectable({providedIn: 'root'})
export class AppConfigService {

    private config: ConsumerAppConfig;

    public config$ = new Subject<ConsumerAppConfig>();
    public appLinks = {};

    constructor(private _http: HttpClient) {
    }

    public initializeAppConfig(): Promise<void> {
        return this._http.get<ConsumerAppConfig>(environment.connectivity.appConfig)
        .toPromise()
        .then((config: ConsumerAppConfig) => {
            this.config = config;
            this.config$.next(this.config);
        }).catch(err => console.error(err));
    }

  public getAppLinksConfig(): Promise<void> {
      return this._http.get(environment.connectivity.appLinksConfig)
      .toPromise()
      .then(
        response => {
          if (response)
            this.appLinks = response;
        }
      ).catch(err => console.log(err));
  }

  getConfig() {
    return this.config;
  }
}
