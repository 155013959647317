<div class="col s12 frame-tile" vcldAutomationId="consr_vto_vis-frame-host_frame-tile_tile">
    <div>
        <div vcldAutomationId="consr_vis-frame-host_frame-tile-click_tile" class="col s12 platinum" [ngClass]="(isSelected$ | async) ? 'border' : 'invisible-border'">
            <div class="btn-wrapper">
                <button mat-icon-button (click)="favoriteSliderFrame(frame)" vcldAutomationId="consr_vto_vis-frame-host_frame-favorite-status_btn">
                    <mat-icon>
                        {{(isFavorite$ | async) ? "favorite" : "favorite_border"}}
                    </mat-icon>
                </button>
                <button mat-icon-button (click)="removeRecommendation(frame.id)" class="quick-silver-text" *ngIf="dmtFlag">
                    <mat-icon matTooltip="{{ 'tooltips.viewer.remove_recommendation' | translate }}">
                        close
                    </mat-icon>
                </button>
                <button disabled mat-icon-button class="remove-btn quick-silver-text" *ngIf="(frame.tint || frame.coating) && !dmtFlag" matTooltip="{{'tooltips.viewer.fav_lenses' | translate}}" matTooltipPosition="above">
                    <i class="icon lenses-fav"></i>
                </button>
            </div>

            <div style="margin-top: -16px;">
                <vcld-secure-image class="tile" width="80%" height="" [ngClass]="!isFrameAvailable ? 'disabled' : null"
                    [imgSrc]="getRecommendedFrameImage(frame.id)">
                </vcld-secure-image>
            </div>
        </div>

        <div id="frame_{{ frame.id }}" class="col s12" vcldAutomationId="consr_vto_vis-frame-host_frame-info_details" [ngClass]="!isFrameAvailable ? 'disabled' : null">
            <p truncate class="bold" vcldAutomationId="consr_vto_vis-frame-host_frame-info-brand_details" [ngClass]="(isSelected$ | async) ? 'high' : ''" vcldTooltip>
                {{ frame.brand }}
            </p>
            <p *ngIf="modelNameFlag" vcldAutomationId="consr_vto_vis-frame-host_frame-info-model_details" [ngClass]="(isSelected$ | async) ? 'high' : ''" vcldTooltip>
                {{ frame.model }}
            </p>
            <p *ngIf="priceFlag" vcldAutomationId="consr_vto_vis-frame-host_frame-info-price_details" [ngClass]="(isSelected$ | async) ? 'high' : ''">
                <ng-container *ngIf="frame.retailPrice">{{ frame.currency }}</ng-container> {{ (frame.retailPrice | formatPrice: true) }}<span *ngIf="frame?.retailPrice">*</span>
            </p>
        </div>

        <div class="col s12 save-height" >
            <button vcldAutomationId="consr_vis-frame-host_Compare-frame-btn" id="cbtn_{{frame.id}}" class="compare-btn cyan-text"
                *ngIf="((selectedFrame$ | async)?.id !== frame.id
                        || frame.coating?.catalogCode != (selectedFrame$ | async)?.coating?.catalogCode
                        || frame.tint?.catalogCode != (selectedFrame$ | async)?.tint?.catalogCode)"
                [disabled]="!(selectedFrame$ | async)" [style.visibility]="isFrameAvailable ? 'visible' : 'hidden'"
                (click)="toggleCompareButton(frame.id)">
                    {{ "components.checkbox.compare" |translate }}
            </button>
        </div>
    </div>
</div>