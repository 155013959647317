/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */

import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { VisucloudRoles } from '../models/vis-role.enum';
import { VisucloudRoleStorage } from '../models/vis-role-storage';
import { VisucloudRole } from '../models/vis-role';
import { ZeissIdToken } from '../models/vis-zeiss-id';
import { SinglePermission, RoleAssignment } from '../models/single-permission.model';
import { HttpClient } from '@angular/common/http';
import { AdminAppEnvironment as environment } from 'visenvironment';
import { map, tap } from 'rxjs/operators';
import { pipe } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class VisucloudRoleService {



  private roleStorage = new VisucloudRoleStorage();

  constructor(private oAuth: OAuthService, private http: HttpClient) { }

  // store all available permissions
  private permissionSet: SinglePermission[];

  // Store the permission for the user role
  private rolePermissionSet: SinglePermission[];

  // Stores the current Role of the User -> from VISUCLOUD
  private activeRole: RoleAssignment;

  private availableRoles: RoleAssignment[];

  // INdicator for pending changes
  private changed = false;

  // Fetch all Permissions
  public fetchPermissionSet() {
    this.http.get<SinglePermission[]>(environment.connectivity.securityEndpoint + '/permissions', {
      headers: {
        Authorization: 'Bearer ' + this.oAuth.getIdToken()
      }
    }).toPromise().then(permissions => {
      if (permissions) {
        this.permissionSet = permissions;
      } else {
        console.error('[PermissionManager] Error in PermissionSet: Received PermissionSet is empty!');
        this.permissionSet = [];
      }
    });
  }

  // Fetch all User Permissions
  public fetchRolePermissionSet() {
    this.http.get<RoleAssignment[]>(environment.connectivity.securityEndpoint + '/role-permissions', {
      headers: {
        Authorization: `Bearer ${this.oAuth.getIdToken()}`
      }
    }).pipe(map(this.combineRoleAssigments)).toPromise().then(role => {
      if (role) {
        this.activeRole = role;
        this.rolePermissionSet = role.permissions;
      } else {
        console.error('[PermissionManager] Error in RolePermissionSet: Received RolePermissionSet is empty!');
        this.permissionSet = [];
      }
    });
  }

  private combineRoleAssigments(roles: RoleAssignment[]): RoleAssignment {
    const tempRole = new RoleAssignment();
    tempRole.id = "CombinedRole";
    tempRole.roleId = "vis-cbd-role";
    tempRole.permissions = [];
    roles.forEach(role => {
      tempRole.permissions.push(...role.permissions);
    });
    const s = new Set<SinglePermission>(tempRole.permissions);
    tempRole.permissions = Array.from(s);
    return tempRole;
  }

  public addPermissions(role: RoleAssignment, permissionToAdd: SinglePermission) {
    // console.log(`[Permission Manager] [Service] Try to addPermission with Key: ${permissionToAdd.key}`);

    const roleIndex = this.availableRoles.findIndex(_r => _r.id === role.id);

    if (roleIndex !== -1) {
      const r = this.availableRoles[roleIndex];
      const permissionIndex = r.permissions.findIndex(_e => _e.key === permissionToAdd.key);
      if (permissionIndex === -1) {
        // console.log('length: ' + r.permissions.length);
        r.permissions.push(permissionToAdd);
        this.availableRoles.splice(roleIndex, 1, r);

        this.changed = true;
      }
    }
  }

  public removePermission(role: RoleAssignment, permissionToRemove: SinglePermission) {

    const roleIndex = this.availableRoles.findIndex(_r => _r.id === role.id);

    if (roleIndex !== -1) {
      const r = this.availableRoles[roleIndex];
      const tempIndex = r.permissions.findIndex(e => e.key === permissionToRemove.key);
      if (tempIndex > -1) {
        r.permissions.splice(tempIndex, 1);
        this.availableRoles.splice(roleIndex, 1, r);
        this.changed = true;
      }
    }
  }

  public savePermissionsSet() {
    // console.log('NEW ROLE PERMISSION SET IS ', this.availableRoles);
    return this.http.put(environment.connectivity.securityEndpoint + '/roles', this.availableRoles, {
      headers: {
        Authorization: `Bearer ${this.oAuth.getIdToken()}`
      }
    }).pipe(tap(e => {
      this.changed = false;
    }));
  }

  public fetchAvailableRoles() {
    this.http.get<RoleAssignment[]>(environment.connectivity.securityEndpoint + '/roles', {
      headers: {
        Authorization: `Bearer ${this.oAuth.getIdToken()}`
      }
    }).toPromise().then((result: RoleAssignment[]) => {
      // console.log(`[Permission Manager] [Serivce] Received available roles`, result);
      this.availableRoles = result.filter(role => role.isConfigurable == true);
    }).catch(ex => {
      console.error(ex);
    });
  }

  public checkAvailableRolePermission(role: RoleAssignment, permissionKey: string): boolean {
    const loadedRole = this.availableRoles.find(r => r.id === role.id);
    return loadedRole.permissions.find(_key => _key.key === permissionKey) ? true : false;
  }


  // Getter
  public getPermissions(): SinglePermission[] {
    return this.permissionSet;
  }

  public getRolePermissions(): SinglePermission[] {
    return this.rolePermissionSet;
  }

  public getActiveRole(): RoleAssignment | null {
    return this.activeRole ? this.activeRole : null;
  }

  public getAvailableRoles(): RoleAssignment[] {
    return this.availableRoles;
  }

  public hasChanges() {
    return this.changed;
  }

  public resetChanges(): void {
    this.changed = false;
  }



  // Public Methods

  public getUserRole(): VisucloudRole {
    return this.parseRole();
  }

  public hasPermission(permissionKey: string): boolean {
    const tempUser = this.getUserRole();
    // console.log('[RoleService] [Check] checking permission ' + permissionKey + ' for permSet: ', tempUser.Permissions);
    if (tempUser) {
      return tempUser.hasPermission(permissionKey);
    }

    return false;
  }

  // Private Helper Methods

  private getRoleTypeByName(name: string): VisucloudRoles {
    switch (name) {
      case 'ZeissBackofficeAdministrator':
        return VisucloudRoles.ZeissBackofficeAdministrator;
      case 'ZeissServiceStaffGlobal':
        return VisucloudRoles.ZeissServiceStaffGlobal;
      case 'ZeissServiceStaffRegional':
        return VisucloudRoles.ZeissServiceStaffRegional;
      case 'ECPManager':
        return VisucloudRoles.ECPManager;
      case 'ECPStaff':
        return VisucloudRoles.ECPStaff;
      case 'RealmManager':
        return VisucloudRoles.RealmManager;
      case 'CentrationOperator':
        return VisucloudRoles.CentrationOperator;
    }
  }

  private getRoleByType(roleType: VisucloudRoles): VisucloudRole {
    let tempRole = null;
    this.roleStorage.getStoredRoles().forEach((role) => {
      if (role.Name === roleType) {
        tempRole = role;
      }
    });

    return tempRole;
  }

  // Reads the Role from the ZeissId Token Claim
  private parseRole(): VisucloudRole {
    const claims = this.oAuth.getIdentityClaims() as ZeissIdToken;
    if (claims) {
      const customFields = JSON.parse(claims.ZeissIdCustomFields);
      const roleType = this.getRoleTypeByName(customFields['admin Role']);
      return this.getRoleByType(roleType);
    }

    return null;
  }
}
