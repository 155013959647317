import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ScrollService {

    constructor() { }

    public scrollDispatcher$: BehaviorSubject<any> = new BehaviorSubject(null);
}