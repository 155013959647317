import { Component, OnInit } from "@angular/core";
import {
    MatBottomSheet,
    MatBottomSheetRef,
} from "@angular/material/bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import { ConsumerAppEnvironment as environment } from "visenvironment";
import { AboutDialogComponent } from "../../dialogs/about/about.dialog.component";
import { DebugService } from "../../services/debug.service";
import { LicensePageComponent } from "../license/license.page.component";

// import { SharedDebuggerService } from 'shared';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "vis-faq-page",
    templateUrl: "./faq.page.component.html",
    styleUrls: ["./faq.page.component.scss"],
})
export class FAQPageComponent implements OnInit {
    private opened: Array<string> = [];

    constructor(
        private bottom: MatBottomSheet,
        private dialogHost: MatDialog,
        private debug: DebugService
    ) {}

    ngOnInit(): void {}

    // Workaround for angular component issue #13870
    disableAnimation = true;
    ngAfterViewInit(): void {
        // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
        setTimeout(() => (this.disableAnimation = false));
    }

    public get versionNumber() {
        return environment.version.split("+").shift();
    }

    public getCSS(id: string): string {
        if (this.opened.includes(id)) {
            return "selected";
        }

        return "";
    }

    public openPanel(id: string) {
        if (!this.opened.includes(id)) {
            this.opened.push(id);
        } else {
            this.debug.log(`the selected id (${id}) is already in list!`);
        }
    }

    public closePanel(id: string) {
        if (this.opened.includes(id)) {
            this.opened.splice(
                this.opened.findIndex((s) => s === id),
                1
            );
        } else {
            this.debug.log(`the selected id (${id}) is not in list!`);
        }
    }

    public openSupport() {
        window.open(environment.contact.form_stack, "_blank", "location=no");
    }

    public openAbout() {
        this.dialogHost.open<AboutDialogComponent>(AboutDialogComponent, {
            autoFocus: false,
        });
    }

    onLicense() {
        this.dialogHost.open(LicensePageComponent, {
            autoFocus: false,
        });
    }
}
