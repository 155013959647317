import { VisucouldPermission } from './vis-permission';
import { VisucloudPermissionCategories } from './vis-permission-category.enum';
import { VisucloudRole } from './vis-role';
import { VisucloudRoles } from './vis-role.enum';
import { VisucloudRoleScope } from './vis-role-scope.enum';
import { VisucloudRoleService } from '../services/auto-auth.service';

// This class will be replace later on by the Database
// TODO: update RoleDefinition by Database handler
export class VisucloudRoleStorage {

    // Application permissions
    private readonly applicationPermissions = [
        // AppComponenten Admin Menu Items
        // 0
        new VisucouldPermission('menu_see_ecp_manager', VisucloudPermissionCategories.CanSee),
        // 1
        new VisucouldPermission('menu_see_ecp_staff', VisucloudPermissionCategories.CanSee),
        // 2
        new VisucouldPermission('menu_see_zeiss_backoffice_admin', VisucloudPermissionCategories.CanSee),
        // 3
        new VisucouldPermission('menu_see_zeiss_service_staff_global', VisucloudPermissionCategories.CanSee),
        // 4
        new VisucouldPermission('menu_see_zeiss_service_staff_regional', VisucloudPermissionCategories.CanSee),
        // 5
        new VisucouldPermission('menu_see_avatar_sessions', VisucloudPermissionCategories.CanSee),

        // AppComponent Start Menu Items
        // 6
        new VisucouldPermission('startmenu_see_help', VisucloudPermissionCategories.CanSee),

        // AvatarSessionListComponent - Working permission
        // 7
        new VisucouldPermission('table_allow_edit', VisucloudPermissionCategories.DoAction),
        // 8
        new VisucouldPermission('table_allow_delete', VisucloudPermissionCategories.DoAction)
    ];

    // ZeissBackofficeAdministrator - Permission Set
    private readonly ZBOPermissions = [
        this.applicationPermissions[2],
        this.applicationPermissions[5],
        this.applicationPermissions[7],
        this.applicationPermissions[8]
    ];

    // ZeissServiceStaffGlobal - Permission Set
    private readonly ZSGPermissions = [
        this.applicationPermissions[3],
        this.applicationPermissions[5]
    ];

    // ZeissServiceStaffRegional - Permission Set
    private readonly ZSRPermissions = [
        this.applicationPermissions[4]
    ];

    // ZeissECPManager - Permission Set
    private readonly ECPMPermissions = [
        this.applicationPermissions[0],
        this.applicationPermissions[5],
        this.applicationPermissions[7],
        this.applicationPermissions[8]
    ];

    // ZeissECPStaff - Permission Set
    private readonly ECPSPermissions = [
        this.applicationPermissions[1],
        this.applicationPermissions[5]
    ];

    // ZeissRealmManager - Permission Set
    private readonly RMPermissions = [
        this.applicationPermissions[0],
        this.applicationPermissions[5],
        this.applicationPermissions[7],
        this.applicationPermissions[8]
    ];

    //ZeissCentrationOperator - Permission Set
    private readonly COPermissions = [
        this.applicationPermissions[0],
        this.applicationPermissions[0]
    ];

    public getStoredRoles(): Array<VisucloudRole> {
        return [
            new VisucloudRole(VisucloudRoles.ZeissBackofficeAdministrator, this.ZBOPermissions, VisucloudRoleScope.Global),
            new VisucloudRole(VisucloudRoles.ZeissServiceStaffGlobal, this.ZSGPermissions, VisucloudRoleScope.Global),
            new VisucloudRole(VisucloudRoles.ZeissServiceStaffRegional, this.ZSRPermissions, VisucloudRoleScope.Regional),
            new VisucloudRole(VisucloudRoles.ECPManager, this.ECPMPermissions, VisucloudRoleScope.Regional),
            new VisucloudRole(VisucloudRoles.ECPStaff, this.ECPSPermissions, VisucloudRoleScope.Regional),
            new VisucloudRole(VisucloudRoles.RealmManager, this.RMPermissions, VisucloudRoleScope.Regional),
            new VisucloudRole(VisucloudRoles.CentrationOperator, this.COPermissions, VisucloudRoleScope.Regional)         
        ];
    }
}
