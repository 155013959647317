import { FavoriteFrame } from './../../models/avatarcreationsession';
import { Component, Inject } from '@angular/core';
import { BehaviorSubject, defer, from, Observable, of } from 'rxjs';
import { SafeUrl } from '@angular/platform-browser';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../../../components/snackbar/snackbar.component';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';
import { map, finalize } from 'rxjs/operators';
import { Frame } from '../../models/frame.model';
import { SessionService } from '../../services/session.service';
import { FrameService } from '../../services/frame.service';
import { ViewerService } from '../../services/viewer.service';


@Component({
    selector: 'vis-frame-selection-dialog',
    templateUrl: './frame-selection.dialog.component.html',
    styleUrls: ['./frame-selection.dialog.component.scss']
})

export class FrameSelectionDialogComponent {

    constructor(
        //public vto: VirtualTryOnService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<FrameSelectionDialogComponent>,
        private snack: MatSnackBar,
        private translate: TranslateService,       
         public _session: SessionService,
        private _frames:FrameService,
        public _viewer:ViewerService
    ) { }

    public favoritedFrames$ = this._frames.favoritedFrames$;
    public favoritesCount$ = this.favoritedFrames$.pipe(
        map(frames => frames?.length || 0)
    )

    public dmtFrames$ = this._frames.dmtRecommendedFrames$;
    public dmtCount$ = this._frames.dmtRecommendedFramesCount$;

    public selectedFrames$: BehaviorSubject<FavoriteFrame[]> = new BehaviorSubject(this.data.frames);

    public maxSelectionAmount: number = 5;

    public getFrameImage(frameId: string): Observable<SafeUrl> {
        return defer(() =>this._frames.getFrameThumbnail(frameId));
    }

    public toggleFrame(frame: Frame): void {

        let included = this.selectedFrames$.getValue().findIndex(x => x.frameId === frame.id && x.coatingId == frame.coating?.catalogCode && x.tintId == frame.tint?.catalogCode) > -1;
        const old = [... this.selectedFrames$.getValue()];
        if (!included && (this.selectedFrames$.getValue().length < this.maxSelectionAmount)) {
            const newFrame = {
                frameId: frame.id,
                coatingId: frame.coating?.catalogCode ? frame.coating?.catalogCode : null,
                tintId: frame.tint?.catalogCode ? frame.tint?.catalogCode: null
            } as FavoriteFrame;

            old.push(newFrame);
            this.selectedFrames$.next(old);
        } else if(included && (this.selectedFrames$.getValue().length > 1)) {
            old.splice(old.findIndex(x => x.frameId === frame.id && x.coatingId == frame.coating?.catalogCode && x.tintId == frame.tint?.catalogCode), 1)
            this.selectedFrames$.next(old);
        } else if (included){
            this.snack.openFromComponent (SnackbarComponent, {
                data: {
                    icon: 'warning',
                    heading: "errors.information",
                    message: "components.dialogs.frame-selection.error_msg",
                    btnIcon: 'close',
                },
                panelClass: 'error-panel',
                verticalPosition: 'top',
                horizontalPosition: 'center'
            });
        }
        

    }

    public submitFrameSelection() {
        if (this.selectedFrames$.getValue().length > 0 && this.selectedFrames$.getValue().length <= this.maxSelectionAmount) {
            this.dialogRef.close(this.selectedFrames$.getValue());
        } else {
            this.snack.openFromComponent(SnackbarComponent, {
                data: {
                    icon: 'warning',
                    heading: "errors.information",
                    message: "components.dialogs.frame-selection.error_msg",
                    btnIcon: 'close',
                },
                panelClass: 'error-panel',
                verticalPosition: 'top',
                horizontalPosition: 'center'
            });
        }
    }

    public hasFavLenses(frame: Frame): boolean {
        return (frame.coating?.catalogCode || frame.tint?.catalogCode) ? true : false;
    }
}