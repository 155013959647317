import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConsumerAppEnvironment as environment } from 'visenvironment';
import { HttpClient } from '@angular/common/http';
import { DebugService } from '../../services/debug.service';

@Component({
    selector: 'vis-unsubscribe',
    templateUrl: './unsubscribe.page.component.html',
    styleUrls: ['./unsubscribe.page.component.scss']
})
export class UnsubscribePageComponent {

    private sessionId: string;

    constructor(private route: ActivatedRoute, private http: HttpClient, private debug: DebugService) {
        this.sessionId = this.route.snapshot.queryParamMap.get('sessionId');
    }

    public unsubscribed: boolean = false;

    public async unsubscribe() {
        this.unsubscribed = true;
        // TODO: Call endpoint for unsubscribe
        const result = await this.postUnsubscribeRequest();
        this.debug.log(result);
    }

    public closeWindow() {
        window.close();
    }

    //unsubscribe method from someone who doesn't know what he's doing
    public async postUnsubscribeRequest() {
        const uri = environment.connectivity.unsubscribeEndpoint;
        return this.http.post(uri, { sessionId: this.sessionId }).toPromise();
    }

}