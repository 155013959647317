import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CacheService } from '../services/cache.service';
import { ConsumerAppEnvironment as environment } from 'visenvironment';
import { tap } from 'rxjs/operators';
import { LoggerService, LogSeverity } from '../services/logger.service';

@Injectable()
export class CacheHttpInterceptor implements HttpInterceptor {
    constructor(private _cache: CacheService, private _log: LoggerService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const { url, method } = req;

        // In case of not cacheable requests
        if (method.toUpperCase() !== 'GET') return next.handle(req);

        const baseUrl = environment.connectivity.esbBaseUrl;
        const transformedUrl = url.replace(baseUrl, '...');
        this._log.log(LogSeverity.INTERCEPT, `intercepting ${transformedUrl} - PENDING`);

        return this.checkCachedData(req, next);
    }

    private checkCachedData(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const { urlWithParams, headers } = request;

        const baseUrl = environment.connectivity.esbBaseUrl;
        const cacheKey = urlWithParams.replace(baseUrl, '...');

        if (headers.has('x-no-cache')) return next.handle(request);

        const result = this._cache.get(cacheKey);
        const reload = headers.has('x-re-cache');

        if (!result || reload) {
            return next.handle(request).pipe(
                tap(e => {
                    if (e instanceof HttpResponse) {
                        this._cache.put(cacheKey, e);
                    }
                })
            );
        }

        return of(result);
    }
}
