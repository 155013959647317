<div class="container">
    <div class="cobranding-container">
        <div class="back-btn-container">
            <button vcldAutomationId="consr_vis-order-page_navigate-back_btn" mat-fab class="circle blured back-btn" (click)="close()">
                <mat-icon>arrow_back_ios_new</mat-icon>
            </button>
        </div>

        <vcld-cobranding class="row" [session]="(_session.selectedSession$ | async)" customStyle="margin-bottom: 0px;" layout="">
        </vcld-cobranding>
    </div>

    <div class="pseudo-alignment">
        <h2 class="flexing bold" vcldAutomationId="consr_vto_vis-order-page_headline_hdr">{{ (orderCount$ | async) > 1 ? ("pages.order.multi_selection" | translate)+"
            ("+(orderCount$ | async)+")" : ("pages.order.selection" | translate)}}</h2>
        <div class="frame-list">
            <div *ngIf="(loading$ | async)" class="multi-frame-element table-overlay">
                <mat-spinner [diameter]="50" mode="indeterminate"></mat-spinner>
            </div>
            <div class="multi-frame-element" [ngStyle]="{'display': (loading$ | async) ? 'none' : 'flex'}"
                [ngClass]="{'multi-background': (orderCount$ | async) > 1}"
                *ngFor="let frame of (selectedFrames$ |async )">
                <mat-icon *ngIf="((orderCount$ |async) > 1) && !alreadySubmitted && (loading$ | async) === false && !loading" class="quick-silver-text mobile-x"
                    (click)="removeFrame(frame)">
                    close</mat-icon>
                <vcld-secure-image class="order-image" [imgSrc]="getFrameImage(frame.id)"></vcld-secure-image>
                <div class="frame-details">
                    <table>
                        <tbody>
                            <!-- Brand -->
                            <tr>
                                <td class="bold">{{ "pages.order.brand" | translate }}</td>
                                <td class="bold">{{frame?.brand}}</td>
                            </tr>
                            <!-- Model Name -->
                            <tr *ngIf="(_viewer.ecpSettings$ | async)?.modelNameEnabled">
                                <td class="bold">{{ "pages.order.model" | translate }}</td>
                                <td class="bold">{{ frame?.model}}</td>
                            </tr>
                            <!-- Color -->
                            <tr *ngIf="(_viewer.ecpSettings$ | async)?.colorEnabled">
                                <td>{{ "pages.order.color" | translate }}</td>
                                <td>{{ frame?.color || "-" }}</td>
                            </tr>
                            <!-- Material -->
                            <tr *ngIf="(_viewer.ecpSettings$ | async)?.materialEnabled">
                                <td>{{ "pages.order.material" | translate }}</td>
                                <td>{{ frame?.material || "-"}}</td>
                            </tr>
                            <!-- Price -->
                            <tr *ngIf="(_viewer.ecpSettings$ | async)?.pricingEnabled" vcldAutomationId="consr_vto_vis-order-page-price_details">
                                <td>{{ "pages.order.price" | translate }}</td>
                                <td><ng-container *ngIf="frame.retailPrice">{{ frame.currency }}</ng-container> {{(frame?.retailPrice | formatPrice: true) }}<span *ngIf="frame?.retailPrice">*</span></td>
                            </tr>
                            <!-- Coating -->
                            <tr *ngIf="frame?.coating">
                                <td>{{'pages.viewer.coating' | translate}}:</td>
                                <td>{{ frame?.coating | tintCoatingName: translationObjName }}</td>
                            </tr>
                            <!-- Tints -->
                            <tr *ngIf="frame?.tint">
                                <td>{{'pages.viewer.tint' | translate}}:</td>
                                <td>{{_tintsCoatings.getTintUIName(frame?.tint)}} {{_tintsCoatings.getTintUIValue(frame?.tint)}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <mat-icon vcldAutomationId="consr_vis-order-page_remove-frame_icon" *ngIf="((orderCount$ |async) > 1) && !alreadySubmitted && (loading$ | async) === false && !loading" class="quick-silver-text remove-circle-outline"
                    (click)="removeFrame(frame)" >
                    remove_circle_outline</mat-icon>
            </div>
        </div>
    </div>


    <div class="fixed-bottom">

        <div class="seperation-line">
            <hr />
        </div>



        <div id="controls">
            <div *ngIf="(loading$ | async) || loading" class="loading">
                <mat-spinner [diameter]="50" mode="indeterminate"></mat-spinner>
            </div>

            <button vcldAutomationId="consr_vis-order-page_add-more-frames_btn" *ngIf="(addableFrames$ | async) && (loading$ | async) === false && !loading" mat-stroked-button color="primary"
                (click)="openFrameSelectionDialog()"
                [disabled]="(_tintsCoatings.isTintsAndCoatingLoaded$ | async) === undefined">
                {{ "pages.order.more_frames" | translate }}
            </button>
            <button vcldAutomationId="consr_vis-order-page_share-your-selection_btn" *ngIf="(loading$ | async) === false && !loading" mat-flat-button color="primary" (click)="openECPDialog()">
                {{ "pages.order.share_selection" | translate }}
            </button>
        </div>

        <div id="confirmation">
            <div class="msg-sent">
                <h4 class="cyan-text bold">
                    <mat-icon class="cyan-text sent-icon">check</mat-icon>
                    {{ "pages.order.optician_informed" | translate }}
                </h4>
            </div>
            <button mat-flat-button color="primary" class="profile-btn" (click)="forceClose()">
                {{ "pages.order.back_to_avatars" | translate }}
            </button>
        </div>
    </div>

    <!-- Footnote Recommended Retail Price -->
    <div *ngIf="(_viewer.ecpSettings$ | async)?.pricingEnabled " class="rrp">
        <p class="quick-silver-text">* {{ "application.rrp" | translate }}</p>
    </div>

</div>