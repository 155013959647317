import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { AdminAppEnvironment } from 'visenvironment';

@Injectable({ providedIn: 'root' })
export class EmailService {

    constructor(private http: HttpClient, private oAuth: OAuthService) { }

    public sendEmail(contactFormObject: any, url?: string) {
        const u = url ? url : AdminAppEnvironment.connectivity.email_endpoint;
        return this.http.post(u, contactFormObject, { headers: { Authorization: 'Bearer ' + this.oAuth.getIdToken() } });
    }
}
