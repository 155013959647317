import { NgModule, ErrorHandler } from '@angular/core';
import { NotFoundComponent } from './components/notFoundComponent/notfound.component';
import { SecureImageComponent } from './components/secureImageComponent/securedImageComponent';

import { GlobalErrorHandler } from './services/globalErrorHandler.service';
import { PendingChangesGuard } from './guards/pendingChanges.Guard';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [
    NotFoundComponent,
    SecureImageComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [
    // {provide: ErrorHandler, useClass: GlobalErrorHandler},
    PendingChangesGuard
  ],
  exports: [
    NotFoundComponent,
    SecureImageComponent
  ]
})
export class UtilitiesModule { }
