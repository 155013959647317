import { AfterViewInit, Component, HostListener, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { MatSlider,MatSliderThumb, MatSliderChange } from '@angular/material/slider';
import { ActivatedRoute, Router } from '@angular/router';
import { CameraSettings, CameraView, GlassesTransformation, VTO } from '@vision/webviewer/lib/vto';
import { Color, LensSettings } from '@vision/webviewer/lib/widget';
import { Observable } from 'rxjs';
import { first, map, shareReplay, tap } from 'rxjs/operators';
import { CoatingObj, TintObj } from '../../../configs/lensSettings.mock';
import { ApplicationInsightsService } from '../../../services/applicationInsights.Service';
import { DebugService } from '../../../services/debug.service';
import { AvatarSource } from '../../models/avatarcreationsession';
import { Frame } from '../../models/frame.model';
import { BinaryType } from '../../services/data.service';
import { DataService } from '../../services/data.service';
import { FrameService } from '../../services/frame.service';
import { SessionService } from '../../services/session.service';
import { TintsCoatingsService } from '../../services/tints-coatings.service';
import { ViewerFactoryService } from '../../services/viewer.factory.service';
import { ViewerService } from '../../services/viewer.service';
import { ChangeContext, Options as SliderOptions } from '@angular-slider/ngx-slider';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'vis-compare-page',
    templateUrl: './compare.page.component.html',
    styleUrls: ['./compare.page.component.scss']
})
export class ComparePageComponent implements OnInit, AfterViewInit {

    // URL: .../compare/:sessionId/:firstFrame/:secondFrame?from=[viewer/gallery]

    private fromQueryFlag: 'viewer' | 'gallery';

    private sessionId: string;

    private firstFrameId: string;
    private secondFrameId: string;

    private leftViewerInstance: VTO;
    private rightViewerInstance: VTO;

    public isLoading: boolean;

    public verticalSliderOptions: SliderOptions = {
        step: 1,
        vertical: true,
        showTicks: false,
        showTicksValues: false,
        ceil: 19,
        floor: 0,
        hidePointerLabels: true,
        hideLimitLabels: true,
        rightToLeft: true
    }

    public horizontalSliderOptions: SliderOptions = {
        step: 1,
        vertical: false,
        showTicks: false,
        showTicksValues: false,
        ceil: 9,
        floor: 0,
        hidePointerLabels: true,
        hideLimitLabels: true,
    }

    // Nose Position
    public sliderLeftValueVertical = 0;
    // Frame Rotation
    public sliderLeftValueHorizontal = 0;
    public sliderLeftTransformation: any;

    // Nose Position
    public sliderRightValueVertical = 0;
    // Frame Rotation
    public sliderRightValueHorizontal = 0;
    public sliderRightTransformation: any;

    public leftFrame$: Observable<Frame>;
    public rightFrame$: Observable<Frame>;

    private minimumDistance: number = 1;
    private maximumDistance: number = 1.6;

    public leftLenses$: Observable<CoatingObj|TintObj>;
    public rightLenses$: Observable<CoatingObj|TintObj>;

    public selectedCoatingLeft: CoatingObj = null;
    public selectedTintLeft: TintObj = null;
    public selectedCoatingRight: CoatingObj = null;
    public selectedTintRight: TintObj = null;

    public translationObjName: string = 'localization';
    private lsLeft: LensSettings;
    private lsRight: LensSettings;
    public sunSliderLeft: boolean = false;
    public sunSliderRight: boolean = false;

    defaultColor: Color = { r: 0, g: 0, b: 0};
    defaultSettingsObj = {
        lensWeight: this.defaultColor,
        lensWeightTop: this.defaultColor,
        antiReflectCoatingReflectivity: this.defaultColor,
        antiReflectCoatingReflectivity90: this.defaultColor,
        antiReflectCoatingPower: 0,
        opacity: 0,
        opacityTop: 0,
        isMirror: false
    } as LensSettings;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private appInsights: ApplicationInsightsService,
        private factory: ViewerFactoryService,
        private debug: DebugService,
        public _session: SessionService,
        public _tintsCoatings: TintsCoatingsService,
        public _frames: FrameService,
        public _viewer: ViewerService,
        private _datacache:DataService,
        private renderer:Renderer2
    ) { }

    
    @ViewChild("sunslider")
    public sunSlider: MatSlider;

    public showSunSlider: boolean = false;
    public sunsliderValue = 0;

    @HostListener('document:click', ['$event'])
    clickout() {
        if(this.showSunSlider) this.showSunSlider = false;
    }

    public initalLoading: boolean = true;

    async ngOnInit() {
        this.sessionId = this.route.snapshot.params.sessionId;
        this.isLoading = true;
        this.initalLoading = true;

        this.mapInternalUrlFragments();
        await this._session.fetchSessionsList();
        await this._session.setSession(this.sessionId);
        await this._frames.initAllFrames(this._session.selectedSession$.getValue().opticianCustomerNumber);
        const tintCoatingscatalogue = this._tintsCoatings.getTintsAndCoatings();
        const ecpSettings = this._viewer.initEcpSettings();
        const result = [await tintCoatingscatalogue,await ecpSettings];
        
        this.initializeTintAndCoating();

        this.leftFrame$ = this._frames.allFrames$.pipe(
            tap(frames => this.debug.log(frames.length)), // Log all frames
            map(frames => frames.find(f => f.id === this.firstFrameId)), // find the selected one
            tap(frame => this.debug.log('frame for right side found: ', frame)), // Log the frame
        );

        this.rightFrame$ = this._frames.allFrames$.pipe(
            tap(frames => this.debug.log(frames.length)), // Log all frames
            map(frames => frames.find(f => f.id === this.secondFrameId)), // find the selected one
            tap(frame => this.debug.log('frame for right side found: ', frame)), // Log the frame
        );

        this.initalizeViewerInstances();
    }

    ngAfterViewInit() {
        this.setSunsliderValuesFromTint();
    }

    private mapInternalUrlFragments(): void {
        this.sessionId = this.route.snapshot.params.sessionId;
        this.debug.log('sessionId: ', this.sessionId);

        this.firstFrameId = this.route.snapshot.params.firstFrame;
        this.debug.log('firstFrameId: ', this.firstFrameId);

        this.secondFrameId = this.route.snapshot.params.secondFrame;
        this.debug.log('secondFrameId: ', this.secondFrameId);

        this.fromQueryFlag = this.route.snapshot.queryParams.from;
    }

    private initializeTintAndCoating() {
        // Lenses of left frame
        if(this.route.snapshot.queryParams?.leftCoating) this.selectedCoatingLeft = this._tintsCoatings.getLensByCatalogCode(this.route.snapshot.queryParams?.leftCoating);

        if(this.route.snapshot.queryParams?.leftTint) {
            this.selectedTintLeft = this._tintsCoatings.getTintByCatalogCode(this.route.snapshot.queryParams?.leftTint);
            this.sunSliderLeft = (((this._tintsCoatings.isPhotoFusionType(this.selectedTintLeft)) && !this._tintsCoatings.isUniColorType(this.selectedTintLeft)) || (this._tintsCoatings.isAdaptiveSunGradientType(this.selectedTintLeft)));
        }

        // Lenses of right frame
        if(this.route.snapshot.queryParams?.rightCoating) this.selectedCoatingRight = this._tintsCoatings.getLensByCatalogCode(this.route.snapshot.queryParams?.rightCoating);

        if(this.route.snapshot.queryParams?.rightTint) {
            this.selectedTintRight = this._tintsCoatings.getTintByCatalogCode(this.route.snapshot.queryParams?.rightTint);
            this.sunSliderRight = (((this._tintsCoatings.isPhotoFusionType(this.selectedTintRight)) && !this._tintsCoatings.isUniColorType(this.selectedTintRight)) || (this._tintsCoatings.isAdaptiveSunGradientType(this.selectedTintRight)));
        };
    }

    private async initalizeViewerInstances(): Promise<void> {
        const start = performance.now();
        var rightViewerInstanceFailed: boolean = false;
        await this._tintsCoatings.getTintsAndCoatings();
        this.mapInternalUrlFragments();

        // Get Avatar Endpoints from Cache
        const avatarOptions = await this.factory.createAvatarOptionsAsync(this.sessionId);

        // Setup the both Instances
        const viewerOptionsLeft = this.factory.createNewViewerInitOptions('viewerWrapperLeft', false, avatarOptions);
        const viewerOptionsRight = this.factory.createNewViewerInitOptions('viewerWrapperRight', false, avatarOptions);

        this.leftViewerInstance = this.factory.createNewInstance();
        this.rightViewerInstance = this.factory.createNewInstance();       

        // Connect synchronized movement
        viewerOptionsRight.master = this.leftViewerInstance;

        // Glass
        viewerOptionsLeft.glasses = await this.factory.createDetailedGlassOptions(this.firstFrameId);
        viewerOptionsRight.glasses = await this.factory.createDetailedGlassOptions(this.secondFrameId);

        viewerOptionsLeft.glassesTransformation = await this.getTransformationMatrix('left', this.firstFrameId);
        viewerOptionsRight.glassesTransformation = await this.getTransformationMatrix('right', this.secondFrameId);

        this.lsLeft = this.getLensSettings(this.selectedCoatingLeft, this.selectedTintLeft);
        this.lsRight = this.getLensSettings(this.selectedCoatingRight, this.selectedTintRight);

        const leftFrame = await this.leftFrame$.pipe(first()).toPromise();
        const rightFrame = await this.rightFrame$.pipe(first()).toPromise();

        if (leftFrame?.color?.includes('SUN') && (!this.selectedCoatingLeft && !this.selectedTintLeft)) {
            try {
                viewerOptionsLeft.lensSettings = await this._frames
                    .sunglassShader(leftFrame.id)
                    .toPromise();
            } catch {
                viewerOptionsLeft.lensSettings = this.defaultSettingsObj;
            }
        }
        else {
            viewerOptionsLeft.lensSettings = this.lsLeft;
        }

        if (rightFrame?.color?.includes('SUN') && (!this.selectedCoatingRight && !this.selectedTintRight)) {
            try {
                viewerOptionsRight.lensSettings = await this._frames
                    .sunglassShader(rightFrame.id)
                    .toPromise();
            } catch {
                viewerOptionsRight.lensSettings = this.defaultSettingsObj;
            }
        }
        else {
            viewerOptionsRight.lensSettings = this.lsRight;
        }

        try {

            // Await all Promises at once => Parallel loading for better performance
            await Promise.all([              
                this.leftViewerInstance
                    .initialize(viewerOptionsLeft)
                    .catch((ex) => {
                        this.debug.log(
                            `[VIEWER] Error has occured while trying to initialize VTO left viewer instance. Error: ${ex}`
                        );
                    }), 
                    this.rightViewerInstance
                    .initialize(viewerOptionsRight)
                    .catch((ex) => {   
                        rightViewerInstanceFailed=true;       
                        this.debug.log(
                            `[VIEWER] Error has occured while trying to initialize VTO right viewer instance. Error: ${ex}`
                        );
                    }),         
            ]);
        }
        catch(error) {
            this.debug.log(
                `[VIEWER] Error has occured while trying to initialize VTO instance. Error: ${ error }`
            );
        }
        if(!rightViewerInstanceFailed) this.setCameraforGlasses();

        this.isLoading = false;
        this.initalLoading = false;

        const time = Math.floor(performance.now() - start);
        this.debug.log(`initialized compare parallel in ${time}ms (${time / 1000}s)`);
        this.appInsights.logMetric('compare_initialize_viewers', time);
    }

    private getLensSettings(coating: CoatingObj, tint: TintObj): LensSettings {
        if (coating && !tint) {
            return this._tintsCoatings.getlensSettingsByCatalogCode(coating.catalogCode);
        } else if (coating && tint) {
            const ls = this.mergeTintAndCoating(tint, this._tintsCoatings.getlensSettingsByCatalogCode(coating.catalogCode));
            return ls;
        } else if (!coating && tint){
            return this.mergeTintAndCoating(tint, this._tintsCoatings.getlensSettingsByCatalogCode('duravision_platinum'))
        }
    }

    public onInputChange(sliderEvent:ChangeContext, sliderPosition: 'left' | 'right', direction: 'vertical' | 'horizontal') {
        const instance = sliderPosition === 'left' ? this.leftViewerInstance : this.rightViewerInstance;
        const index:any = sliderEvent.value;
        const m = this.getTransformationMatrixPosition(sliderPosition, direction, index);
        if (m === null) return;
        instance.setGlassesTransformation(m);
        this.debug.log(`[CompareView] [Slider] [${sliderPosition}] incoming value is: ${index}`);
    }

    public getTransformationMatrixPosition(instance: 'left' | 'right', direction: 'vertical' | 'horizontal', index: number) {

        const transformation = instance === 'left' ? this.sliderLeftTransformation : this.sliderRightTransformation;

        let appliedTransformation = null;

        if (direction === 'vertical') {
            const positions = transformation.verticalPositions[index].positions;
            appliedTransformation = positions[instance === 'left' ? this.sliderLeftValueHorizontal : this.sliderRightValueHorizontal];
        }

        if (direction === 'horizontal') {
            const positions = transformation.verticalPositions[instance === 'left' ? this.sliderLeftValueVertical : this.sliderRightValueVertical].positions;
            appliedTransformation = positions[index];
        }

        if (appliedTransformation === null) return null;
        const matrix = this.convertTo4x4(appliedTransformation);

        return matrix;
    }

    public mergeTintAndCoating(t: TintObj, l: LensSettings) {
        const copiedLensSettings = Object.assign({}, l);
        const tt = t.settings.topColor;
        const tb = t.settings.bottomColor;

        copiedLensSettings.lensWeightTop = { r: tt.r, g: tt.g, b: tt.b };
        copiedLensSettings.lensWeight = { r: tb.r, g: tb.g, b: tb.b };

        copiedLensSettings.opacity = t.settings.bottomColor.lowestAbsorption;
        copiedLensSettings.opacityTop = t.settings.topColor.lowestAbsorption;
        return copiedLensSettings;
    }

    private async getTransformationMatrix(instance: 'left' | 'right', frameId: string): Promise<GlassesTransformation> {
        const transformation = await this._frames.getFrameTransformations(frameId, this.sessionId).toPromise();
        const bestFit = transformation.bestFit;

        if (instance === 'left') {
            this.sliderLeftTransformation = transformation;
            this.sliderLeftValueHorizontal = bestFit.position === 0 ? 5 : bestFit.position;
            this.sliderLeftValueVertical = bestFit.verticalPosition;
        } else {
            this.sliderRightTransformation = transformation;
            this.sliderRightValueHorizontal = bestFit.position === 0 ? 5 : bestFit.position;
            this.sliderRightValueVertical = bestFit.verticalPosition;
        }

        const positions = transformation.verticalPositions[bestFit.verticalPosition].positions;
        const appliedTransformation = positions[instance === 'left' ? this.sliderLeftValueHorizontal : this.sliderRightValueHorizontal];

        const matrix = this.convertTo4x4(appliedTransformation);

        return matrix;
    }

    private convertTo4x4(obj) {
        const transformation = obj.transformation;
        const leftPad = obj.leftPadTransformations;
        const rightPad = obj.rightPadTransformations;

        const m: GlassesTransformation = {
            frame: [
                transformation.a11, transformation.a21, transformation.a31, transformation.a41,
                transformation.a12, transformation.a22, transformation.a32, transformation.a42,
                transformation.a13, transformation.a23, transformation.a33, transformation.a43,
                transformation.a14, transformation.a24, transformation.a34, transformation.a44
            ],
            padLeft: (leftPad == null) ? null : [
                leftPad.a11, leftPad.a21, leftPad.a31, leftPad.a41,
                leftPad.a12, leftPad.a22, leftPad.a32, leftPad.a42,
                leftPad.a13, leftPad.a23, leftPad.a33, leftPad.a43,
                leftPad.a14, leftPad.a24, leftPad.a34, leftPad.a44
            ],
            padRight: (rightPad == null) ? null : [
                rightPad.a11, rightPad.a21, rightPad.a31, rightPad.a41,
                rightPad.a12, rightPad.a22, rightPad.a32, rightPad.a42,
                rightPad.a13, rightPad.a23, rightPad.a33, rightPad.a43,
                rightPad.a14, rightPad.a24, rightPad.a34, rightPad.a44
            ]
        };

        return m;
    }

    public isViewerInitialized() {
        return this.leftViewerInstance && this.rightViewerInstance;
    }

    public isAvatarVisible() {
        if (this.isViewerInitialized()) {
            return this.leftViewerInstance.isAvatarVisible() && this.rightViewerInstance.isAvatarVisible();
        }

        return false;
    }

    public toggleAvatarVisibility() {
        const current = this.leftViewerInstance.isAvatarVisible() && this.rightViewerInstance.isAvatarVisible();
        this.leftViewerInstance.setAvatarVisible(!current);
        this.rightViewerInstance.setAvatarVisible(!current);
    }

    public close() {
        this.leftViewerInstance.pause();
        this.rightViewerInstance.pause();

        if (this.fromQueryFlag === 'viewer') {  
            this.router.navigate([`/viewer/${this.sessionId}`], { queryParams: { 
                    frameId: this.firstFrameId, 
                    tint: this.selectedTintLeft?.catalogCode,
                    coating: this.selectedCoatingLeft?.catalogCode
                } });
        } else if (this.fromQueryFlag === 'gallery') {
            this.router.navigate([`/gallery/${this.sessionId}`],{
                queryParams: {
                    mode: this.route.snapshot.queryParams.mode,
                    scrollPosition:this.route.snapshot.queryParams.scrollPosition,
                    endIndex:this.route.snapshot.queryParams.endIndex,
                    dmt:this.route.snapshot.queryParams.dmt
                },
            });
        } else {
            this.router.navigate(['/profile']);
        }
    }

    public switchToOrderRight() {
        var origin = this.route.snapshot?.queryParams?.from;
        this.screenshotFromAngel(0.9,this.rightViewerInstance).then((_) => {
            this.router.navigate(
                [
                    `order/${this.sessionId}/${this.secondFrameId}`,
                ],
                {
                    queryParams: {
                        from: "compareRight",
                        frameId: this.firstFrameId,
                        origin: origin,
                        tint: this.selectedTintRight?.catalogCode,
                        coating: this.selectedCoatingRight?.catalogCode,
                        firstTint: this.selectedTintLeft?.catalogCode,
                        firstCoating: this.selectedCoatingLeft?.catalogCode
                    }
                });
        });
    }

    public switchToOrderLeft() {
        this.screenshotFromAngel(0.9,this.leftViewerInstance).then((_) => {
            this.router.navigate(
                [
                    `order/${this.sessionId}/${this.firstFrameId}`,
                ],
                { queryParams: { 
                    from: "compareLeft", 
                    frameId: this.secondFrameId,
                    origin: this.route.snapshot?.queryParams?.from,
                    tint: this.selectedTintLeft?.catalogCode,
                    coating: this.selectedCoatingLeft?.catalogCode,
                    secondTint: this.selectedTintRight?.catalogCode,
                    secondCoating: this.selectedCoatingRight?.catalogCode
                } }
            );
        });

    }

    public setCameraforGlasses(){

        let width = (document.getElementsByTagName("canvas")[0].clientWidth) / 1000;
        let height = (document.getElementsByTagName("canvas")[0].clientHeight) / 1000;

        height = (Math.floor(height * 100) / 100);
        width = (Math.floor(width * 100) / 100);

        if(width < height) {
            this.minimumDistance = (Math.round((width * 1.9) * 100) / 100);
            this.maximumDistance = (Math.round((width * 3) * 100) / 100);
        } else {
            this.minimumDistance = (Math.round((width * 0.8) * 100) / 100);
            if(this.minimumDistance<0.5) this.minimumDistance = 0.5;
            this.maximumDistance = (Math.round((width * 1.8) * 100) / 100); 
        }
            this.leftViewerInstance.setCameraSettings(this.factory.createCompareCameraSettings(this.minimumDistance, this.maximumDistance), false);
            this.rightViewerInstance.setCameraSettings(this.factory.createCompareCameraSettings(this.minimumDistance, this.maximumDistance), false);
    }

    private async screenshotFromAngel(quality: number = 0.8, selectedViewerInstance:VTO) {
        const n = [
            [0.93938, -0.3428, -0.001622],
            [-0.003197, -0.003, -1],
            [0.3, 0.934, -0.004],
        ];

        let multiplier = 2.3;
        let t = { x: -0.11 * multiplier, y: -0.326 * multiplier, z: 0 };

        const session = {...this._session.selectedSession$.getValue()};

        // Cameron
        if(this._session.selectedSession$.getValue().source === AvatarSource.CAMERON) {
            if (selectedViewerInstance.isAvatarVisible()) {
                multiplier = 1.0;
                t = { x: -0.11 * multiplier, y: -0.326 * multiplier, z: 0.04 };
            }
            else{
                multiplier = 2.5;
                t = { x: -0.12 * multiplier, y: -0.350 * multiplier, z: 0.05 };
            }
        }



        const a: CameraView = { rotation: n, translation: t };

        if (!selectedViewerInstance.isAvatarVisible()) {
            selectedViewerInstance.setAvatarVisible(true);
            if(this._session.selectedSession$.getValue().source === AvatarSource.CAMERON){
                await selectedViewerInstance.takeScreenshot(a);
            }
        }
        
        if (true) {
            const image = await selectedViewerInstance
                .takeScreenshot(a);
            return this.getBlobFromImageData(image, quality, session.id);
        }
    }

    private getBlobFromImageData(image: ImageData, quality, sessionId: string): Promise<void> {
        return new Promise((resolve) => {
            try {
                const { width, height } = image;

                const canvas = this.renderer.createElement("canvas");

                canvas.width = width;
                canvas.height = height;

                const ctx = canvas.getContext("2d");
                ctx.putImageData(image, 0, 0);

                canvas.toBlob(
                    async (blob) => {
                        try {
                            const blobUrl = URL.createObjectURL(blob);
                            await this._datacache.setCacheEntryAsync(
                                sessionId,
                                BinaryType[BinaryType.AVATAR_THUMBNAIL],
                                blobUrl
                            );
                            await this._datacache
                                .setAvatarThumbnail(sessionId, blob)
                                .toPromise();
                        } catch (error) {
                            
                        }
                        
                        resolve(void 0);
                    },
                    "image/jpeg",
                    quality
                );
            } catch (error) {
                this.debug.log(error);
            } finally {
                resolve(void 0);
            }
        });
    }

    private setSunsliderValuesFromTint() {
        const low = 0;
        const high = 100;

        if (this.sunSlider) {
            this.sunSlider.min = low;
            this.sunSlider.max = high;
           // this.sunSlider.value = low;
        }
    }

    public onSunSliderInputChange(sliderEvent:Event) {
        if (!sliderEvent) return;
        const value:any=(sliderEvent.target as HTMLInputElement).value;
        this.debug.log(`The emitted value of the slider is ${value}`);

        this.updateLensSettingsLeft(value);
        this.updateLensSettingsRight(value);
    }

    private updateLensSettingsLeft(eventValue) {
        if (!this.sunSliderLeft || !this.selectedTintLeft) return;

        this.lsLeft = this.updateValuesByReference(this.selectedTintLeft, this.lsLeft, eventValue);
        this.leftViewerInstance.setLensSettings(this.lsLeft);
    }

    private updateLensSettingsRight(eventValue) {
        if (!this.sunSliderRight || !this.selectedTintRight) return;

        this.lsRight = this.updateValuesByReference(this.selectedTintRight, this.lsRight, eventValue);
        this.rightViewerInstance.setLensSettings(this.lsRight);
    }

    private updateValuesByReference(tint:TintObj, lensSettings: LensSettings, eventValue): LensSettings {
        // opacity
        lensSettings.opacity =
        ((tint.settings.bottomColor.highestAbsorption -
            tint.settings.bottomColor.lowestAbsorption) /
            100) *
            eventValue +
        tint.settings.bottomColor.lowestAbsorption;

         // opacityTop
         lensSettings.opacityTop =
         ((tint.settings.topColor.highestAbsorption -
             tint.settings.topColor.lowestAbsorption) /
             100) *
             eventValue +
         tint.settings.topColor.lowestAbsorption;

         return lensSettings;
    }
}
